<div class="container mt-90">
    <div class="main">
        <div>

        </div>
        <h2 class="text-center">Contract For The Transportation Of Vehicles</h2>
        <ol>
            <p>This agreement is entered into this <input type="text" placeholder="Month" [(ngModel)]="month"> day of <input type="text" placeholder="Day" [(ngModel)]="day"> between AQ Movers,
                Brokers, here in after referred to as BROKER (MC 01323510) and <input type="text" [(ngModel)]="name" placeholder="Carrier Name"> here in after referred
                to as CARRIER (MC <input type="text" placeholder="Mc#" [(ngModel)]="mcNo"> ). The terms and conditions shall be as follows:</p>
            
            <li>
                <span>INDEPENDENT CONTRACTOR:</span> The parties agree that both CARRIER and BROKER are independent
                contractors and
                that their relationship as all times shall be viewed as such.
            </li>
            <li>
                <span>SERIES OF SHIPMENTS AND DISTINCT NEEDS:</span> BROKER agrees to provide CARRIER with the
                opportunity for
                shipments within the scope of the CARRIER'S authority, and CARRIER agrees to transport any tendered
                shipments promptly and efficiently, subject to the terms and conditions specified herein to meet the
                distinct needs of the BROKER.
            </li>
            <li>
                <span>METHODOLOGY OF RATE MAKING:</span> Rates shall be negotiated at the time of shipment and shall be
                agreed upon
                prior to CARRIER loading any vehicles. In the event that the rates are based on mileage, all miles will
                be based on either PC* MILER or MILE MAKER, as designated by BROKER' S customer. A rate confirmation
                sheet shall be completed and signed by both parties and made a part of the contract. Payment to CARRIER
                by BROKER shall prevail as the contract rate or charge, and under-charge claims will not be honored
                unless presented within 60 days.
            </li>
            <li>
                <span>PAYMENT PROCEDURE:</span> BROKER shall pay CARRIER within two (2) business days after receipt of
                payment from
                BROKER'S customer. BROKER cannot bill the customer until BROKER has received proof of delivery from
                CARRIER, including all transport damage, shipping order, CARRIER operating authority permits,
                certificates of insurance, and any other documents necessary to invoice the customer. In the event
                CARRIER collects at the delivery point, the moneys shall be payable to BROKER. CARRIER agrees that it
                always shall be responsible for collecting on C.O.D. deliveries.
            </li>
            <li>
                <span>REIMBURSEMENT BY OFFSET:</span> In the event of any damage to vehicles while under its direction,
                possession,
                or control, CARRIER agrees to reimburse BROKER for all such damage claims paid by BROKER no later than
                thirty (30) days after notification from BROKER of such damage claims. CARRIER agrees that BROKER may
                withhold settlement amounts otherwise due CARRIER until such reimbursement is made, and if not made
                within thirty (30) days of notification , then BROKER may, in the sole exercise of its discretion,
                withhold and offset the amount of the damage claims from CARRIER' S settlement before paying the balance
                to CARRIER.
            </li>
            <li>
                <span>CARRIER'S RIGHTS AND DUTIES:</span> On each shipment, CARRIER shall issue a standard Bill of
                Lading and the
                traffic shall move under the terms and conditions of this Bill of Lading. Where there is a conflict bet
                ween the Bill of Lading and this contract, the provisions of this contract shall prevail. CARRIER shall
                be liable for all loss, damage, or liability occasioned by the transportation of property for which
                BROKER has arranged while such property is under the care, custody, or control of CARRIER.
            </li>
            <li>
                <span>SOLE CARRIER:</span> CARRIER shall not cause or permit any shipment to be transported by any other
                motor
                carrier other than CARRIER, or any other mode of transport at ion other than that owned by CARRIER.
            </li>
            <li>
                <span>NON-SOLICITATION BY CARRIER:</span> CARRIER agrees that during the term of this agreement and for
                one (1) year
                after its termination, under no circumstances will it directly or indirectly communicate with customers
                referred to CARRIER by BROKER. CARRIER further agrees that neither it nor its agents, nor anyone under
                its control or with whom it is associated in whatever capacity, will approach customers (back
                solicitations) introduced to it by BROKER and offer its services directly or indirectly, or accept
                traffic from such customers without BROKER'S participation. CARRIER agrees that if it breaches this
                restriction on soliciting customers introduced by BROKER, CARRIER will pay BROKER the highest percentage
                of revenue previously paid to BROKER on all traffic obtained by each breach. These payments shall
                continue during the period in which this agreement remains in force and for a period of one (1) year
                after termination of the agreement by either party.
            </li>
            <li>
                <span>BROKER'S RIGHTS AND DUTIES:</span> BROKER shall uphold the good reputation of CARRIER and shall
                not
                misrepresent the services and abilities of CARRIER.
            </li>
            <li>
                <span>COMPLIANCE WITH REGULATIONS:</span> CARRIER shall be responsible for complying with all applicable
                state and
                federal regulations, including those for the Federal Highway Administration and Department of Transport
                at ion, as applicable.
            </li>
            <li>
                <span>DAMAGE TO SHIPMENTS:</span> CARRIER will be liable for the full loss or any damages resulting from
                loss,
                injury, damage, or delay on shipments. For the purposes of this agreement, "full loss” means the
                replacement or repair costs of the lost or damaged freight or any part thereof. Repairs to vehicles
                shall be performed by entities approved by the manufacturer, and prior to payment for such repairs,
                CARRIER shall be provided with reasonable information in support of such repair costs. If the
                manufacturer determines vehicles shall not be repaired, a claim will be processed utilizing the actual
                cost of the loss, minus the salvage value.

                The liability shall be for the full value of the item, which shall be understood to mean the replacement
                cost of the lost or dam aged item(s); except that if the shipper of the cargo is also the manufacturer
                (or its agent ) of the commodities shipped and an implied or expressed warranty of such commodities by
                the manufacturer exists, then the manufacturer of such commodity shall have sole discretion to establish
                the extent and amount of such loss up to the full value of the commodity and/ or reclassification of a
                vehicle changing it to used car status, constituting an amount of diminished value. The manufacturer
                shall also retain any salvage there from, except that CARRIER shall be allowed any sums acquired by the
                manufacturer from the disposal of said salvage.
            </li>
            <li>
                <span>INDEMNITY:</span> CARRIER agrees to indemnify, defend, and hold BROKER harmless as follows: a) for
                loss of or
                damage to CARRIER’ S equipment; b) for loss resulting from injury, including death, sustained by CARRIER
                or by any employees of CARRIER, or by any other person while acting in the capacity of the driver or
                helper in connection with the operation of the equipment utilized herein, including the payment by
                CARRIER of any worker’s compensation benefit s or unemployment compensation benefit s, as w ell as any
                additional benefit s paid under "No Fault " and "Personal Injury Protection" law s in any state; c) for
                any bodily injury , property damage, or cargo loss or damage including the defense of lawsuits there
                from arising out of the maintenance, use, or operation of the motor vehicle equipment utilized by
                CARRIER herein; and d) for any loss or damage sustained by BROKER in remedy, of by BROKER against
                CARRIER in any litigation brought against BROKER because of any act or omission of CARRIER under this
                agreement .
            </li>
            <li>
                <span>INSURANCE:</span> CARRIER warrants that it has liability insurance in an amount equal to ICC
                requirements of
                $750,000 or greater, and cargo insurance in the amount of $500,000 or greater per occurrence. CARRIER
                further warrants it will maintain said cargo and liability insurance for the duration of this agreement
                and provide BROKER with a certificate naming BROKER as Additional Insured.
            </li>
            <li>
                <span>EXCEPTION FOR UNCONTROLLABLE FORCE:</span> Neither party hereto will be liable for the failure to
                tender or
                timely transport freight under this agreement if such failure, delay, or other omission is caused by
                strikes, acts of God, war, accident s, civil disorder, or through compliance with legally constituted
                order of civil or military authorities.
            </li>
            <li>
                <span>ENTIRE AGREEMENT:</span> This document and BROKER'S freight bill contain the entire agreement
                between the
                parties and no add it ions or alterations shall be effective unless in writing and signed by both
                parties.
            </li>
            <li>
                <span>COUNTERPARTS:</span> This agreement may be signed in two (2) or more counterparts, each of which
                shall be
                deemed an original and which together shall be considered one and the same document. A signed original
                of this agreement that is transmit ted by facsimile shall be considered an original for all purposes.
            </li>
        </ol>
    </div>
    <div class="signatures">
        <div class="singnature">
            <form [formGroup]="AgreementForm">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        formControlName="check">
                    <label class="form-check-label" for="flexCheckDefault">
                        I agree on terms and conditions
                    </label>
                    <mat-error
                        *ngIf="AgreementForm.controls.check?.invalid && AgreementForm.controls.check?.touched">
                        <mat-error *ngIf="AgreementForm.get('check')?.hasError('required')">
                            This field is required
                        </mat-error>
                    </mat-error>
                </div>

                <input class="input" type="text" name="name" id="name" placeholder="Enter your name"
                    formControlName="name" required [(ngModel)]="name">
                <mat-error *ngIf="AgreementForm.controls.name?.invalid && AgreementForm.controls.name?.touched">
                    <mat-error *ngIf="AgreementForm.get('name')?.hasError('required')">
                        This field is required
                    </mat-error>
                </mat-error>
                <br>
                <input class="input" type="date" name="date" id="date" placeholder="Enter Date"
                    formControlName="date" required [(ngModel)]="date">
                <mat-error *ngIf="AgreementForm.controls.date?.invalid && AgreementForm.controls.date?.touched">
                    <mat-error *ngIf="AgreementForm.get('date')?.hasError('required')">
                        This field is required
                    </mat-error>
                </mat-error>
            </form>

            <div class="signature-container">
                <signature-pad (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
            </div>
            <p>Signature here...</p>

            <div class="buttons">
                <button (click)="clearSignature()">Clear signature pad</button>
                <button (click)="savePad()" [disabled]="!AgreementForm.valid"> Submit</button>
            </div>

        </div>
        <!-- <div class="singnature">
            <h5>BROKER</h5>
            <form [formGroup]="AgreementForm2">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        formControlName="check2">
                    <label class="form-check-label" for="flexCheckDefault">
                        I agree on terms and conditions
                    </label>
                    <mat-error
                        *ngIf="AgreementForm.controls.check2?.invalid && AgreementForm.controls.check?.touched">
                        <mat-error *ngIf="AgreementForm.get('check2')?.hasError('required')">
                            This field is required
                        </mat-error>
                    </mat-error>
                </div>
                <input class="input" type="text" name="name2" id="name2" placeholder="Enter your name"
                    formControlName="name2" required [(ngModel)]="name2">
                <mat-error *ngIf="AgreementForm.controls.name2?.invalid && AgreementForm.controls.name2?.touched">
                    <mat-error *ngIf="AgreementForm.get('name2')?.hasError('required')">
                        This field is required
                    </mat-error>
                </mat-error>

                <input class="input" type="text" name="date2" id="date2" placeholder="Enter Date"
                    formControlName="date2" required [(ngModel)]="date2">
                <mat-error *ngIf="AgreementForm.controls.date2?.invalid && AgreementForm.controls.date2?.touched">
                    <mat-error *ngIf="AgreementForm.get('date2')?.hasError('required')">
                        This field is required
                    </mat-error>
                </mat-error>
            </form>

            <div class="signature-container">
                <signature-pad (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
            </div>
            <p>Signature here...</p>

            <div class="buttons">
                <button>Clear signature pad</button>
                <button> Submit</button>
            </div>

        </div> -->
    </div>

    <div class="end">
        <p>THIS CONTRACT MUST BE COMPLETED BY A CORPORATE OFFICER OR BY THE LEGAL OWNER. <br> OR EMAIL
            carrier.agreements@aqmovers.com</p>
        <p>PLEASE SEND ORIGINAL, COMPLETED IN FULL AND SIGNED IN BLUE INK TO:</p>
        <p>AQ Movers <br>
            <span  (click)="OpenMap()" style="cursor: pointer; font-weight: 600;">79 Southern Blvd, Nesconset, NY 11767, USA</span>  <br>
            <a href="tel:+1 (339) 237-3082">(339) 237-3082</a>
            
        </p>
    </div>

</div>