<!-- footer -->
<div class="footer-upper">
	<div class="row">
		<div class="col-lg-3 col-md-3 col-sm-3 col-lg-3 col-xs-12">
			<!-- <h2 >Stalk us</h2>
			<ul class="list-contact">
				<li class="mb-3">
					<a href="https://web.facebook.com/profile.php?id=100077859527774&_rdc=1&_rdr" target="_blank">
						<img src="../../assets/images/footer/facebook.png" class="icon-f" alt=""> Facebook
					</a>
				</li>
				<li class="mb-3">
					<a href="https://www.instagram.com/qurantumqts/" target="_blank">
						<img src="../../assets/images/footer/instagram.png" class="icon-f" alt=""> Instagram
					</a>
				</li>
				<li class="mb-3">
					<a href="https://twitter.com/AQ MoversTranspo4" target="_blank">
						<img src="../../assets/images/footer/twitter.png" class="icon-f" alt=""> Twitter
					</a>
				</li>

				<li class="mb-3">
					<a href="https://www.linkedin.com/in/American-Auto-Transports-75b8a3230/" target="_blank">
						<img src="../../assets/images/footer/linkedin.png" class="icon-f" alt=""> Linkedin
					</a>
				</li>
			</ul> -->
			<!-- <div>
					<h2>Reviews</h2>
					<ul class="list-contact">
						<li>
							<a href="#">
								Google Reviews
							</a>
						</li>
					</ul>
				</div> -->
		</div>
		<div class="col-sm-12 col-md-9 col-lg-5 col-xs-12">
			<h2 class="sh-h2-resources">Quick Links</h2>
			<div class="row Quick">
				<div class="col-md-6 col-sm-12">
					<ul class="list-contact">
						<li>
							<img src="../../assets/images/footer/house.png" class="icon-f" alt=""> <span
								routerLink="">Home</span>
						</li>
						<li>
							<img src="../../assets/images/footer/who are we@25px.png" class="icon-f" alt=""> <span
								routerLink="about_us">About Us</span>
						</li>
						<li>
							<img src="../../assets/images/footer/what is AQ Movers@25px.png" class="icon-f" alt=""> <span
								routerLink="why-us">Why Us?</span>
						</li>
						<li>
							<img src="../../assets/images/footer/blogging.png" class="icon-f" alt=""> <span
								routerLink="Blogs">Blogs</span>
						</li>
						<li>
							 <span
								routerLink="terms">Terms of Service</span>
						</li>
					</ul>
				</div>
				<div class="col-md-6 col-sm-12">
					<ul class="list-contact">
						<li>
							<img src="../../assets/images/footer/contact us50px.png" class="icon-f" alt=""> <span
								routerLink="contact_us">Contact Us</span>
						</li>
						<li>
							<img src="../../assets/images/footer/faq.png" class="icon-f" alt=""> <span
								routerLink="faq">FAQ</span>
						</li>
						<li>
							<img src="../../assets/images/footer/how it works@25px.png" class="icon-f" alt=""> <span
								routerLink="how-it-work">How it Works?</span>
						</li>
						<!-- <li>
							<img src="../../assets/images/footer/pricing@25px.png" class="icon-f" alt=""> <span
								routerLink="payment">Payment via CC</span>
						</li> -->
						<li>
							<span
								routerLink="privacy">Privacy Policy</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
			<h2>
				<a class="foot-logo" routerLink="">
					<img src="../../assets/images/footer/purpal-white QTS.png" alt="logo">
				</a>
			</h2>
			<div class="list-contact">
				<li>
					<a class="email" href="mailto:contact@aqmovers.com"
						target="_blank">contact@aqmovers.com</a>
				</li>

				<li>
					<a href="tel:+1 (339) 237-3082"><i class="fa fa-phone"></i>(339) 237-3082</a>
				</li>
				<li class="fv3-contact">
					<span class="f-15">79 Southern Blvd, Nesconset, NY 11767, USA</span>
				</li>
			</div>
			<!-- <div>
				<h2>Licensed and Bonded</h2>
				<p class="text-white">USDOT# 3739931</p>
				<p class="text-white">MC-01323510</p>
			</div> -->
		</div>
	</div>
</div>
<div class="footer-lower">
	<div class="footer footer-text ptb-10 clearfix">
		<div class="row">
			<div class="col-lg-8 col-md-12 col-sm-12">
				<p class="text-wh">Copyright © 2023, AQ Movers. All rights reserved.</p>
			</div>
			<div  class="col-lg-4 col-md-12 col-sm-12">
				<div  class="social-link">
					<a  class="footer-link-border"
						onclick="topFunction()" href="/terms">Terms of Service</a>
						<a 
						onclick="topFunction()" href="/privacy">Privacy Policy</a></div>
			</div>
		</div>
	</div>
	<div class="h-60px"></div>
</div>
<!-- //copyright -->
<!-- move top icon -->
<!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
<footer class="footerss">
    <div class="container">
        <div class="row bikeImg">
            <div class="col-lg-3 col-md-5 col-sm-5 d-flex justify-content-center">
                <div class="d-block justify-content-end d-lg-block"
                    style="max-height: 50px !important; overflow-y: visible">
                    <img alt="Ship your vehicle" src="../assets/images/states-images/car-image.png" class="img-fluid imageHide" />
                </div>
            </div>
            <div class="col-lg-5 col-sm-5 col-md-5 text-center mt-2">
                <div class="d-flex justify-content-center">
                    <h5 class="adjustSize">
                        Flash Sale - 35% Off only for a limited time.
                    </h5>
                </div>
            </div>

            <div class="col-lg-2 col-sm-2 d-md-2 text-center d-lg-block">
                <div class="text-center">
                    <a class="btn quote" (click)="gerQuote()">
                        Get a Quote
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-0 d-md-none d-lg-block d-flex justify-content-start">
                <div class="text-center">
                    <a class="btn Call text-nowrap" href="tel:+1 (339) 237-3082">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-telephone d-xs-none" viewbox="0 0 16 16">
                            <path
                                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        (339) 237-3082
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
<a class="move-top text-center">
	<span class="fa fa-envelope" aria-hidden="true" (click)="openDialog()"></span>
</a>
<!-- //move top icon -->