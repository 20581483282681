<div class="container mt-90">
    <div class="main">
        <h2 class="text-center">Shipper’s Agreement</h2>
        <ol>
            <li>
                The carrier and driver jointly and separately are authorized to operate and transport his/her or their
                motor vehicle between its pickup location and the destination. In the case that effort to provide service is not provided,
                then deposits are non-refundable. Every effort will made to ship the vehicle within promise period
                but delays can occur due to carrier schedules, mechanical failure, and inclement weather, acts of God,
                among other unforeseen circumstances for which it can take up to 2 weeks. AQ Movers
                will not be responsible for any charges or liabilities incurred due to delay of pickup or delivery. This
                includes but is not limited to airline tickets or rental car fees. The client will be given the
                carrier’s schedule at the time of dispatch. The client agrees to release AQ Movers Solution from
                any liability and waive their right to sue AQ Movers LLC, or their employees,
                officers, volunteers and agents (collectively “District”) from any and all claims.
            </li>
            <li>
                The client agrees to not contract any other broker or carrier during the respective time which
                corresponds with their shipping option. Any client that is found working with another broker or carrier
                during this period, is subject to a non-refundable deposit fee. The initial deposit fee and/or any other
                transport fees are non-refundable after initiation/completion of the online order process via the credit
                card payment form on the <a
                    href="https://aqmovers.com/"><b>aqmovers.com</b></a> website by
                the customer.
            </li>
            <li>
                Contracted carriers provide door-to-door transport if the truck driver can physically reach the pick-up
                and delivery addresses. If access to the pickup or delivery location is restricted by narrow streets,
                low-hanging trees, or tight turns, the driver may ask that you meet the truck at a large parking lot
                nearby, such as a grocery store.
            </li>
            <li>
                Carriers are not licensed or insured to transport any personal or household goods, however, we do
                understand that you may need to put some items in the vehicle. Carrier is not liable for damage caused
                to the vehicle from excessive or improper loading of personal items. These items must be put in the
                trunk and kept to a limit of 100 lbs. Any exceptions must be previously discussed and approved by
                AQ Movers. An additional fee may be assessed for personal items of any weight. Any
                misrepresentation of the personal belongings will result in a change of price and/or a dry run fee of
                $150 if a carrier is made to attend the scene of the pick-up and the shipment is different from
                expected. If a carrier is sent out and the vehicle is not ready as indicated by the shipper there will
                be an additional $75.00 rescheduling fee. AQ Movers must be notified, should the
                shipper be unavailable for pick up or delivery, the shipper must have an alternate representative take
                his/her place as a shipper.
            </li>
            <li>
                Vehicles must be tendered to the carrier in good running condition with no more than a half tank of
                fuel. Carrier will not be liable for damage caused by leaking fluids, freezing, exhaust systems, or
                antennas not tied down. Any claim for loss or damage must be noted and signed on the condition report at
                the time of delivery.
            </li>
            <li>
                Trucking damage claims are covered by carriers from $100,000 up to $250,000 cargo insurance per load,
                and a minimum of 3/4 of a million dollars public liability and property damage. Any damage incurred to a
                vehicle during transport falls directly under the responsibility of the carrier and not AQ Movers
                Transport Solutions. All carriers contractor will have insurance to cover damage caused by the driver,
                carrier or carrier’s contractor, weather, act of god, vandalism and or theft during transport. If damage
                is done, AQ Movers will provide you with a full insurance packet for the carrier to
                file a claim. AQ Movers Solution is not responsible for damage caused by driver, carrier or
                carrier’s contractor, weather, act of god, vandalism and or theft during transport. All claims must be
                noted and signed for at the time of delivery and submitted in writing within 15 days of delivery.
            </li>
            <li>
                If a carrier is sent out and the vehicle is not ready as indicated by the shipper there will be an
                additional $75.00 rescheduling fee. AQ Movers must be notified, should the shipper be
                unavailable for pick up or delivery, the shipper must have an alternate representative take his/her
                place as a shipper. If for any reason the vehicle becomes unavailable during a scheduled pick-up window,
                after an order has been placed, AQ Movers will not refund the deposit amount.
            </li>
            <li>
                The client should under no circumstances release or receive vehicle(s) from a carrier without an
                inspection report (Bill of Lading/BOL) regardless of the time of day or the weather conditions. Failure
                to do so may result in the client’s inability to file a damage claim. Carriers insurance will only
                process claims for damages due to the carrier’s negligence. Damage must be reported to AQ Movers
                Solutions within 24 hours of delivery. Damage must be listed on the BOL and signed by the driver (no
                exceptions). If there is damage during transport, the client must notate those damages on the final
                inspection report, pay the remaining balance stated on this agreement, and then contact the carrier’s
                main office as well as the carrier's insurance company. Failure to notate any damage on the final
                inspection report releases the carrier of any liability and would result in the inability to process a
                damage claim. It is customer’s responsibility to review Carrier’s dispatch sheet and confirm the
                customer correct name and address and verify the identity of the truck driver prior to release the
                vehicle for transport. AQ Movers Solution is an acting agent. All claims must be made with the
                carrier if any circumstances arises. Any/All damages are covered by the carrier’s insurance and must be
                claimed carrier’s insurance, not AQ Movers. In the condition of lost or stolen
                vehicle, all claims must be made with Carrier’s insurance.
            </li>
            <li>

                Dispatched orders must be canceled by calling the offices of AQ Movers at <span><a
                        href="tel:+1 (339) 237-3082"><b>(339) 237-3082</b></a></span> or sending an email to
                info@aqmovers.com. Cancellations must be made no less than 24 hours before the pick-up
                date. Cancellations of dispatched orders are subject to a non-refundable $200 fee.
            </li>
            <li>
                While every effort is made to dispatch the vehicle(s) on customer's requested scheduled date and the
                original quoted price to the customer but due to continuous fluctuations in gas/diesel prices, shortage
                of drivers, inclement weather conditions, continuous inflation among many other factors along with the
                customer's requested shipping date, there is a possibility where the customer will be requoted for a new
                price which could have a price hike of up to 25% more than the original quote. Customer must agree to
                this new quote or if customer does not agrees to the update quote then cancelation charges apply.
                Customer waives the right to any/all refund requests if the customer wants to cancel the agreement
            </li>
            <li>
                A $150.00 non-operational fee will be charged for all non-running vehicles. This will be included in the
                final quote received from AQ Movers. If the vehicle becomes non-operational during
                transport, this fee will be applied to the original quote.
            </li>
            <li>
                By signing this subsequent settlement agreement the client or “customer” agrees, and shall not
                disparage, criticize, defame or slander against AQ Movers or its employees,
                contractors,
                supplier’s, carriers, drivers, production or services in any manner. “I”, “we” or “customer” further
                agrees
                not to initiate or participate in any Negative Communications, written, oral, Google reviews, Facebook
                or
                any other social media which impacts AQ Movers solution or damaging the reputation of AQ Movers
                Transport solutions, this including any defamatory remarks in regards to the operation of AQ Movers
                Transports
                solutions. Failure to adhere to this agreement, the “shipper” or AQ Movers Solution may claim
                for
                damages from “Customer” including cost, expenses and compensation, including out of pocket expenses,
                loss of
                use, lost income, lost profits, attorney’s fees, punitive damages, or any other damages or expenses of
                any
                kind or character whatsoever, whether based on tort, contract, or other theory of recovery, whether
                known or
                unknown, which have arisen in the past or which may arise in the future, whether directly or indirectly,
                caused by, connected with, resulting from, or arising in any way out of any and all claims embodied in
                the
                Lawsuit. This release and discharge shall be a fully binding and complete settlement among all Parties
                to
                this Agreement and their heirs, assigns, and successors. <br>
                The <b>Consumer Review Fairness Act</b> does not include any non-disparagement agreements that are
                included
                in a
                settlement agreement after a dispute arises. It also doesn't apply to negotiated, non-form contracts. It
                also won't protect you if your review includes false information or knowing exaggerations of what
                actually
                occurred.
            </li>
            <li>
                AQ Movers agrees to provide a carrier to transport your vehicle as promptly as
                possible under your instructions but cannot guarantee pick-up or delivery on a specified date. A
                cancellation fee of $200 will be charged for orders canceled 7 days before the requested available
                pick-up date. AQ Movers does not agree to pay for your rental of a vehicle, nor shall
                it be liable for the failure of mechanical or operating parts of your vehicle. Shipper warrants that
                he/she will pay the price quoted due to AQ Movers for delivered vehicles and will not
                seek to chargeback a credit card. This agreement and any shipment hereunder are subject to all terms and
                conditions of the carrier’s tariff and the uniform straight bill of lading, copies of which are
                available at the office of the carrier.
            </li>
            <li>
                This agreement shall be governed by and construed under the laws of the State of Texas. The parties
                further agree that any legal action arising out of this agreement must be filed in a court of Dallas
                County. AQ Movers’ liability is limited to the amount of money collected by AQ Movers
                Transport Solutions or its affiliates to “broker’s fee” only. The client hereby submits to the
                jurisdiction of such courts and waives any right to jurisdiction in any other location. I hereby agree
                to the transport terms provided by AQ Movers. I authorize a small down payment to be
                paid to AQ Movers via a CashApp, Zelle, PayPal, Credit or Debit card or Check by phone
                or mail. I further understand that any remaining balance is due on delivery and that it must be paid in
                full via cash, cashier’s check, and money order, to the authorized transporter.
            </li>
        </ol>

    </div>

    <div class="singnature">
        <form [formGroup]="AgreementForm">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="check">
                <label class="form-check-label" for="flexCheckDefault">
                    I agree on terms and conditions
                </label>
                <mat-error *ngIf="AgreementForm.controls.check?.invalid && AgreementForm.controls.check?.touched">
                    <mat-error *ngIf="AgreementForm.get('check')?.hasError('required')">
                        This field is required
                    </mat-error>
                </mat-error>
            </div>
            <input class="input" type="text" name="name" id="name" placeholder="Enter your name" formControlName="name"
                required [(ngModel)]="name">
            <mat-error *ngIf="AgreementForm.controls.name?.invalid && AgreementForm.controls.name?.touched">
                <mat-error *ngIf="AgreementForm.get('name')?.hasError('required')">
                    This field is required
                </mat-error>
            </mat-error>
        </form>

        <!-- <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad> -->
        <div class="signature-container">
            <signature-pad (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
        </div>
        <p>Signature here...</p>

        <div class="buttons">
            <button (click)="clearSignature()">Clear signature pad</button>
            <!-- <button (click)="savePad()">Save signature</button> -->
            <button (click)="savePad()" [disabled]="!AgreementForm.valid"> Submit</button>
        </div>
        <!-- <div class="signature-image" *ngIf="signatureImg">
            <img src='{{ signatureImg }}' />
          </div> -->
    </div>
</div>