    <div  class="terms-container-wrapper ">
        <div  class="row m-0">
            <div  class="col-md-12 col-sm-12 col-xs-12">
                <div  class="terms-condition-wrapper container">
                    <h1 >Privacy Policy</h1>
                    <hr >
                    <p > This AQMovers Privacy Policy (the "Privacy Policy" or “Policy”) applies to
                        AQMovers Website, Mobile Apps and all other Platforms, Products and Services operated and
                        provided by AQ Movers LLC, its brands under the domain and sub-domains of <a
                             href="https://www.aqmovers.com/">www.aqmovers.com </a>and it’s Mobile
                        Apps, AI and all its platforms, services and technologies (TOGETHER, THE “AQ Movers LLC SERVICE”
                        or “AQMovers SERVICE” or “SERVICE” or “SERVICES” or “PRODUCTS”). By visiting, registering or
                        using the AQMovers Website, Mobile Apps and all other Platforms, Products and Services operated
                        and provided by AQ Movers LLC or when you otherwise interact with us with or without a signed-up
                        AQMovers account, you agree to accept and be subject to and bound by the terms of use of this
                        “Policy” and expressly consent to our use and disclosure of your personal information in
                        accordance with this Policy. You must agree to and accept all of the “Terms of Use” available on
                        the AQMovers website and this “Privacy Policy”, or you don't have the right to use or access any
                        of the “AQMovers” “Services”.&nbsp;&nbsp;Your using or accessing any of the Services in any way,
                        means that you agree to all of these Terms and Policy and you hereby consent that we will
                        collect, use, and share your information in the following ways. These Terms and Policy will
                        remain in effect while you use the Services.&nbsp;If you do not wish to accept the present
                        “Terms of Use” and “Policy”, do not use the AQMovers Services and uninstall any AQMovers
                        applications from your device and stop accessing AQMovers Website, Mobile Apps, Technologies,
                        AI, Platforms, Products, Services or other Products or other Services operated and provided by
                        AQ Movers LLC immediately. These Terms include the provisions in this document, those in
                        the&nbsp;“Terms of Use”&nbsp;as well as guidelines, policies, and regulations that AQMovers
                        makes available on the Services and are subject to change from time to time.</p>
                    <p > This Policy describes the information, as part of the normal operation of
                        our services that we collect from you and what may happen to that information. Our subsidiaries,
                        suppliers and affiliates operate under similar privacy practices as described in this Policy and
                        are subject to the requirements of applicable laws. </p>
                    <p >AQMovers encourages you to periodically review this page for any changes to
                        its Privacy Policy.</p>
                    <p ><strong >1. Definitions</strong><br
                            > 1a. aqmovers.com Service, Website, Service, Mobile Apps and all other
                        Platforms, Products and Services operated and provided by AQ Movers LLC refers to the electronic
                        commerce service owned and operated by AQ Movers LLC, which provides a listing of international,
                        federal, state, county, city and other governing authority procurement and AQ Movers opportunities.
                        <br > 1b. “aqmovers.com, its Website, Mobile Apps and all other Platforms,
                        Products and Services operated and provided by AQ Movers LLC refers to “AQ Movers LLC” or
                        “AQMovers”. <br > 1c. “Subscriber(s)” or “User(s)” refers to persons,
                        organizations or entities that use this AQMovers Website, Mobile Apps and all other Platforms,
                        Products and Services operated and provided by AQ Movers LLC in accordance with its “Privacy
                        Policy” and “Terms of Use”. In this Policy Agreement, “We”, “Us”, “Our” and “You” means any
                        person, organization or other entity applying to register or use or subscribe with
                        the&nbsp;Website, Mobile Apps and all other Technology Platforms and Services operated by
                        AQ Movers LLC. 1c. “Subscriber(s)” or “User(s)” refers to persons, organizations or entities that
                        use this AQMovers Website, Mobile Apps and all other Platforms, Products and Services operated
                        and provided by AQ Movers LLC in accordance with its “Privacy Policy” and “Terms of Use”. In this
                        Policy Agreement, “We”, “Us”, “Our” and “You” means any person, organization or other entity
                        applying to register or use or subscribe with the&nbsp;Website, Mobile Apps and all other
                        Technology Platforms and Services operated by AQ Movers LLC. <br > 1d.
                        “Information" refers to all of the information, software and other material provided in
                        connection with or available through the AQMovers Website, Service, Mobile Apps and all other
                        Platforms, Products and Services operated and provided by AQ Movers LLC. <br
                            > 1e. The privacy practices of this statement applies to aqmovers.com
                        Service, Website, Service, Mobile Apps and all other Platforms, Products and Services operated
                        and provided by AQ Movers LLC. </p>
                    <p ><strong >2. Information We Collect</strong><br
                            >
                        Our primary purpose in collecting personal information from you is to provide you with a safe,
                        smooth, efficient, and customized experience. This allows us to provide services and features
                        that will meet your needs, and to customize our service to make your experience safer and
                        easier. We only collect personal information about you that we consider necessary for achieving
                        this purpose. Once you give us your personal information, you are not anonymous to us. If you
                        choose to use our service, we may require you to provide contact and identity information,
                        billing information, shipping information and other personal information as indicated on the
                        forms throughout AQMovers. Where possible, we indicate which fields are required and which
                        fields are optional. You always have the option to not provide information by choosing not to
                        use a particular AQMovers service or feature. Under some circumstances, we may require some
                        additional financial information, such as, but not limited to, credit card information. We use
                        your financial information, including credit card information, to verify the accuracy of your
                        name, address, and other information, as well as to bill you for your use of our products and
                        services. We automatically track certain information about you based upon your behavior on our
                        Site, Mobile Apps and our other technology platforms. We use this information to do internal
                        research on our users' demographics, interests, and behavior to better understand, protect and
                        serve you and our community. This information may include the URL that you just came from
                        (whether this URL is on our Site or not), which URL you next go to (whether this URL is on our
                        Site or not), your computer browser information, and your IP address. We use cookies on our Web
                        site to check your preferences and to maintain session information, including identification of
                        users and preparation of customized Web Pages. Web Browsers set aside a small amount of space on
                        your hard drive to keep these preferences, and then every time you visit a Web site your browser
                        checks to see if you have any predefined preferences (cookies) for that server. If you do, it
                        sends the cookie to the server along with the request for a web page. Your browser will not give
                        up its cookie data to any server except the one that set it. If you establish a credit account
                        with us to pay the fees we charge, we collect some additional information, such as: a billing
                        address, credit card number and credit card expiration date and tracking information from checks
                        or money orders. If you send us personal correspondence, such as emails or letters, we may
                        collect such information into a file specific to you.</p>
                    <p ><strong >3. Our Disclosure of Your
                            Information</strong><br > As a matter of policy, we do not sell or rent
                        any of your personal information to third parties for their marketing purposes.</p>
                    <p ><strong >4. Internal Service Providers for Our
                            Operations</strong><br > We may use third parties that we refer to as
                        internal service providers to facilitate or outsource one or more aspects of the business,
                        product and service operations that we provide to you (e.g. couriers, mail, and bill collection)
                        and therefore we may provide some of your personal information directly to these internal
                        service providers. These internal service providers are subject to confidentiality agreements
                        with us and other legal restrictions that prohibit their use of the personal information we
                        provide them for any other purpose except to facilitate the specific outsourced by AQMovers
                        Website, Service, Mobile Apps and all other Platforms, Products and Services operated and
                        provided by AQ Movers LLC related operation. </p>
                    <p ><strong >5. External Service Providers</strong><br
                            > There are a number of separate services offered by third parties that
                        we refer to as external service providers that may be complementary to your use of AQMovers. If
                        you choose to use these separate services, disclose information to the external service
                        providers, and/or grant them permission to collect information about you, then their use of your
                        information is governed by their privacy policy. We do not disclose your personal information to
                        external service providers unless you provide your explicit consent. With your consent, we may
                        provide some of your personal information to the external service provider offering such
                        services, for your convenience. To prevent our disclosure of your personal information to an
                        external service provider, you can decline such consent or simply not use their services.
                        Because we do not control the privacy practices of these third parties, you should evaluate
                        their practices before deciding to use their services. </p>
                    <p ><strong >6. Other Corporate Entities</strong><br
                            > We may share our data, including personal information about you, with
                        our subsidiaries and joint ventures or potential partners or assignors. To the extent that these
                        entities may have access to your information, they will treat it at least as protectively as
                        they treat information they obtain from their other users. Our subsidiaries, joint ventures,
                        potential partners or assignors shall follow privacy practices no less protective of all users
                        than our practices described in this document, to the extent allowed by applicable law. </p>
                    <p ><strong >7. Other Information Collectors</strong><br
                            > Except as otherwise expressly included in this Privacy Policy, this
                        document only addresses the use and disclosure of information we collect from you. To the extent
                        that you disclose your information to other parties, whether they are other users of AQMovers
                        Website, Service, Mobile Apps and all other Platforms, Products and Services operated and
                        provided by AQ Movers LLC or other sites throughout the Internet, different rules may apply to
                        their use or disclosure of the personal information you disclose to them. Since AQMovers does
                        not control the privacy policies of third parties, you are subject to the privacy customs and
                        policies of that third party. We encourage you to ask questions before you disclose your
                        personal information to others.</p>
                    <p ><strong >8. Our Use of Your Information</strong><br
                            >
                        We use your personal information to facilitate the services you request. We use personal
                        information in the file we maintain about you, and other information we obtain from your current
                        and past activities on AQMovers Website, Service, Mobile Apps and all other Platforms, Products
                        and Services operated and provided by AQ Movers LLC to: resolve disputes; troubleshoot problems;
                        bill your account; inform you about online and offline offers, products, services, and updates;
                        customize your experience; detect and protect us against error, fraud and other criminal
                        activity; enforce our User Agreement; and as otherwise described to you at the time of
                        collection, use or disclosure. At times we look across multiple users to identify problems or
                        resolve disputes, and in particular we may examine your personal information to identify users
                        using multiple User ID's or aliases. We may compare and review your personal information for
                        errors, omissions and for accuracy. If you choose to open a AQMovers account, or otherwise
                        provide us your financial information, we use your address and financial information to bill you
                        and provide associated support. You agree that we may use personal information about you to
                        improve our marketing and promotional efforts, to analyze AQMovers Website, Service, Mobile Apps
                        and all other Platforms, Products and Services operated and provided by AQ Movers LLC usage, to
                        improve our content and product offerings, and to customize AQMovers Website, Service, Mobile
                        Apps and all other Platforms, Products and Services operated and provided by AQ Movers LLC
                        content, layout, and services. These uses improve AQMovers Website, Service, Mobile Apps and all
                        other Platforms, Products and Services operated and provided by AQ Movers LLC and better tailor
                        it to meet your needs, so as to provide you with a smooth, efficient, safe and customized
                        experience while using AQMovers. You agree that we may also use your personal information to
                        contact you and deliver information to you that, in some cases, are targeted to your interests,
                        administrative notices, product offerings, and communications relevant to your use of AQMovers.
                        By accepting the AQMovers “Terms of Use” and “Privacy Policy”, you expressly agree to receive
                        this information. You may make changes to your notification preferences at any time.</p>
                    <p ><strong >9. Control of Your Password</strong><br
                            > You are responsible for all actions taken with your User ID and
                        password, including fees charged to your account. Therefore, we do not recommend that you
                        disclose your AQMovers Website, Service, Mobile Apps and all other Platforms, Products and
                        Services operated and provided by AQ Movers LLC passwords to any third parties. If you choose to
                        share your AQMovers User ID and password or your personal information with third parties, you
                        are responsible for all actions taken with your account and therefore you should review that
                        third party's privacy policy before doing so. If you lose control of your password, you may lose
                        substantial control over your personal information and may be subject to legally binding actions
                        taken on your behalf. Therefore, if your password has been compromised for any reason, you
                        should immediately change your password.</p>
                    <p ><strong >10. Accessing, Reviewing and Changing Your
                            Personal Information</strong><br > We offer you the ability to
                        automatically review and change the personal information you submit to us by logging into
                        AQMovers Website and Mobile Apps operated and provided by AQ Movers LLC and entering the new
                        information yourself. Generally, we prefer not to modify your personal information based upon
                        your request but it is possible to do so. You can change your password, email address, contact
                        information, financial information, shipping information and user preferences. You are
                        responsible for and must promptly update your information if it changes or is inaccurate. Users
                        or Subscribers, who have opted for a AQMovers subscription plan, may cancel their subscription
                        online or upon written request at any time but will be liable for the payment due of the entire
                        subscription period with no refunds issued. We will deactivate your account, contact
                        information, billing information, shipping information and financial information from our active
                        databases. To make this request, please contact us at contact@aqmovers.com. We will retain in
                        our files some personal information you have requested us to remove in order to prevent fraud,
                        collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations,
                        enforce our User Agreement and comply with legal requirements as is permitted by law. Therefore,
                        you should not expect that all of your personal information will be completely removed from our
                        databases in response to your requests. However, such personal information will be deactivated
                        from public and member viewing and will only be available to select AQ Movers LLC, affiliates or
                        subsidiaries personnel.</p>
                    <p ><strong >11. Security</strong><br
                            > Your information is stored and processed on our servers. AQMovers
                        Website, Service, Mobile Apps and all other Platforms, Products and Services operated and
                        provided by AQ Movers LLC uses procedural and technical safeguards to protect your personal
                        information against loss or theft, as well as against unauthorized access or disclosure, to
                        protect your privacy, including firewalls and encryption. We employ many different security
                        techniques to protect such data from unauthorized access by users inside and outside the
                        company. However, "perfect security" does not exist on the Internet. The security of your
                        information is important to us. We have security measures in place to protect against the loss,
                        misuse and alteration of information under our control. We also follow generally accepted
                        industry standards to protect the information transmitted to us over the Internet, both during
                        transmission and once we receive it. Sensitive information is encrypted using secure socket
                        layer technology (SSL). However, no method of transmission over the Internet, or method of
                        electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable
                        means to protect your information, we cannot guarantee its absolute security.</p>
                    <p ><strong >12. Legal Requests</strong><br
                            > AQMovers Website, Service, Mobile Apps and all other Platforms,
                        Products and Services operated and provided by AQ Movers LLC cooperates with law enforcement
                        inquires, as well as other third parties to enforce laws such as: intellectual property rights,
                        fraud and other rights, to help protect you and the AQMovers community from bad actors.
                        Therefore, in response to a verified request by law enforcement or other government officials
                        relating to a criminal investigation or alleged illegal activity, we can (and you authorized us
                        to) disclose your name, city, state, province, telephone number, email address, and User ID
                        history without a subpoena. However, in an effort to balance your right to privacy and the
                        ability to keep the community free from bad actors, we will not disclose additional information
                        to law enforcement or other government officials without a subpoena, court order or
                        substantially similar legal procedure, except when the additional disclosure of information is
                        necessary to prevent imminent physical harm or financial loss.</p>
                    <p ><strong >13. Marketing Communication</strong><br
                            > We may communicate with you by email or in app notification in
                        connection with our Services/Platform to confirm your registration, to inform you in case your
                        product listing has become live/expired. If you opt out of receiving marketing or commercial
                        communications, we retain the right to send you non-marketing communications such as
                        correspondence about your relationship with us or notifying you of updates to our Privacy
                        Notices or Terms of Use. Please note that we also carry out digital advertising campaigns from
                        time to time that does not rely on your personal data. </p>
                    <p ><strong >14. Notice - Amendments</strong><br
                            > We may amend this Privacy Policy at any time by posting the amended
                        terms on the AQMovers Website, and/or Mobile Apps with or without notice to you. All amended
                        terms shall automatically be effective immediately after they are posted on AQMovers website
                        and/or it’s Mobile Apps. </p>
                </div>
            </div>
        </div>
    </div>