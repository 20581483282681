<div class="container mt-90">
    <h1 class="container p-3 text-center">
        Difference Between AQ Movers And Other Transport
        Companies
    </h1>

    <section class="container mt-4 row m-md-0 m-md-auto">
        <div class="col-lg-6 d-flex justify-content-center align-items-center">
            <img src="../../../assets/images/states-images/tracking.png" alt="img" />
        </div>

        <div class="col-lg-6 mt-2">
            <p >
                When it comes to transporting motorcycles, almost all the <a href="/Motorcycle-Shipping" target="_blank"><b>motorcycle
                    transport</b></a> companies are capable of transporting the vehicle to your
                destination. But, there are certain specific qualities of AQ Movers
                transport solutions that distinguish our service from that of our
                competitors.
            </p>
            <a href="" class="px-2"><button class="btn text-nowrap bgCOLOR1" (click)="getQuote()">Get a Quote</button></a>
            <h5 class="mt-3">
                Here are a few distinct features of our transportation service:
            </h5>
            <ul>
                <li>Instant quote and book your order</li>
                <li>24/7 customer support and guidance</li>
                <li>No need to empty the fuel tank before transportation</li>
                <li>Wide range of distribution centers across the country</li>
                <li>No need to remove your battery from the motorcycle</li>
                <li>Door-to-Door transportation service</li>
                <li>Personalized guidance and support</li>
            </ul>
        </div>
    </section>

    <section class="container">
        <h3 class="text-center fw-bolder mt-3">
            What Do AQ Movers Offer?
        </h3>

        <div class="row mt-4">
            <div class="col-lg-4">
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../../../assets/images/states-images/247.png" alt="img" class="icons" />
                </div>

                <h5 class="text-center mt-3">24/7 Service</h5>
                <p class="p-2">
                    We are not a typical 9-5 transport service. We work hard 24/7
                    thought out 365 days of the year to ensure the best bike transport
                    service to our customers. We are always ready to respond to your
                    queries and help you transport your bike across the country without
                    worrying about anything.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../../../assets/images/states-images/support.png" alt="img" class="icons" />
                </div>
                <h5 class="text-center mt-3">Dedicated Guidance And Support</h5>
                <p class="p-2">
                    Our transport experts are committed to providing you with the best
                    scooter transport service for your motorcycle. We monitor all the
                    vehicles and bikes until they arrive at their destination to ensure
                    100% efficient service.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../../../assets/images/states-images/noUpfront.png" alt="img" class="icons" />
                </div>
                <h5 class="text-center mt-3">$0 Upfront Payment</h5>
                <p class="p-2">
                    Yes, you read it right, zero upfront payment.We ship motorcycles for
                    you without any upfront payment for the transport. We charge you
                    only when your vehicle is dispatched to your desired location.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../../../assets/images/states-images/customerService Png.png" alt="img" class="icons" />
                </div>
                <h5 class="text-center mt-3">Personalized Service</h5>
                <p class="p-2">
                    Here at AQ Movers, we guide you through all the
                    steps of transporting your bike. After choosing AQ Movers
                    solutions for your car and <a href="/Motorcycle-Shipping" target="_blank"><b>motorcycle shipping</b></a>, you’ll experience
                    peace of mind as we make it hassle-free and easy for you to
                    transport your vehicle to your destination.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../../../assets/images/states-images/insurancePng.png" alt="img" class="icons" />
                </div>
                <h5 class="text-center mt-3">Insurance Coverage</h5>
                <p class="p-2">
                    By selecting our service, you can send motorcycle to any part of the
                    country with proper insurance coverage. A transport carrier can only
                    become part of our service when he meets all the requirements for
                    the insurance of vehicles being transported. We offer a certain
                    amount of insurance for the vehicle depending on the type and model
                    of the vehicle. If a customer wants insurance more than the fixed
                    amount, he can ask us and we make sure to provide the required
                    insurance coverage to our customer.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../../../assets/images/states-images/effice.png" alt="img" class="icons" />
                </div>
                <h5 class="text-center mt-3">Efficient And Effective Service</h5>
                <p class="p-2">
                    Unlike other transporters, QTS ensures to provide you an efficient
                    and cheap <a href="/Motorcycle-Shipping" target="_blank"><b>motorbike transport service</b></a> in an effective way. We work
                    round the clock to help you transfer your vehicles across the
                    country using minimum time and effort.
                </p>
            </div>
        </div>
    </section>

    <section class="container mt-4 wColor p-4">
                    <h2 class="d-flex align-items-center justify-content-center">
                        Want To Transport Your Bike Through Our Distribution Centers?
                    </h2>
                    <p>
                        AQ Movers have a vast network of distribution
                        centers all over the country. Transporting your vehicle through
                        our distribution centers often saves you time and money. In this
                        case, you don’t need to wait for <a href="/Motorcycle-Shipping" target="_blank"><b>motorcycle transport</b></a>carriers to
                        come and pick up your motorcycle.
                    </p>
    </section>
    <div class="mt-5 whColor">
        <section class="container">
                <div class="row">
                    <div class="col-lg-6 d-flex align-items-center justify-content-center">
                        <img src="../../../assets/images/states-images/truck1.jpg" alt="img"
                            class="w-100" />
                    </div>
                    <div class="col-lg-6">
                        <div class="d-flex justify-content-center align-items-center">
                            <p>
                                You can leave your bike at your convenience at our
                                distribution center and we’ll deliver it to your location in
                                no time. So, if you are thinking about transporting your bike
                                through our distribution center, make sure to give us a call
                                at <a href="tel:+1 (339) 237-3082"><b>(339) 237-3082</b></a> so that we can arrange for your shipment and
                                prepare the carrier for your motorcycle.
                            </p>
                        </div>
                        <a class="btn text-nowrap bgCOLOR1 mb-3" (click)="getQuote()">
                            Get a Quote
                        </a>
                    </div>
                </div>
        </section>
    </div>

    <section class="container mt-4">
        <h2>
            Shipment Of A Motorcycle And Car Together
        </h2>
                    <p>
                        Are you looking for a transport service that could transport your
                        car and motorcycle together? Well, we provide first-class
                        transportation of multi-vehicles to your destination.
                    </p>
    </section>
    <div class="mt-5">
        <section class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="d-flex justify-content-center align-items-center">
                            <p>
                                In case of more than one vehicle, we are capable of
                                transferring your vehicles by different transport carriers. In
                                case of any query, just give us a call at <a href="tel:+1 (339) 237-3082"><b>(339) 237-3082</b></a>.
                            </p>
                        </div>
                        <a class="btn text-nowrap bgCOLOR1 mb-3" (click)="getQuote()">
                            Get a Quote
                        </a>
                    </div>
                    <div class="col-lg-6 d-flex align-items-center justify-content-center">
                        <img src="../../../assets/images/states-images/truck4.jpg" alt="img"
                            class="w-100" />
                    </div>
                </div>
        </section>
    </div>
</div>