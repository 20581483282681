<!-- inner banner -->
<div class="inner-banner-w3ls d-flex flex-column justify-content-center align-items-center">
</div>
<!-- //inner banner -->
<!-- breadcrumbs -->
<nav aria-label="breadcrumb">
    <ol class="breadcrumb d-flex justify-content-center">
        <li class="breadcrumb-item">
            <a routerLink="">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">About Us</li>
    </ol>
</nav>
<!-- //breadcrumbs -->

<!-- about-->
<section class="single_grid_w3_main align-w3">
    <div class="container">
        <div class="text-center our-dream">
            <h1 class="w3pvt-title">Our dream is</h1>
            <h5 class="w3pvt-title">that shipping a vehicle would become the most seamless and enjoyable experience for our clients.</h5>
        </div>
        <div class="row three-section">
            <div class="col-lg-6 align-self-center">
                <div class="wthree_pvt_title  mb-3">
                    <span class="sub-title">That’s why...</span>
                    <h4 class="w3pvt-title">Let’s AQ Movers It!
                    </h4>

                </div>
                <div class="single_grid_text pt-lg-3">
                    <p>AQ Movers's ultimate goal is to become the industry standard for vehicle shipping, serving a diverse range of individual clients, corporations of all sizes, and business partners across the United States, and also to be known as the company that made transporting a vehicle as simple as eating food.</p>
                </div>
            </div>
            <div class="col-lg-6 mt-lg-0 mt-4">
                <div class="slide-img-2">
                    <img src="../../assets/images/ab.jpg" class="img-fluid" alt="" />
                </div>
            </div>
        </div>

        <div class="row three-section">
            <div class="col-lg-6 mt-lg-0 mt-4">
                <div class="slide-img-2">
                    <img src="../../assets/images/g5.jpg" class="img-fluid" alt="" />
                </div>
            </div>
            <div class="col-lg-6 align-self-center">
                <div class="wthree_pvt_title  mb-3">
                    <span class="sub-title">We will achieve it by...</span>
                    <h4 class="w3pvt-title">Continuing to keep our emphasis on what makes us successful
                    </h4>

                </div>
                <div class="single_grid_text pt-lg-3">
                    <p>Our objective at AQ Movers is to provide a 5-star vehicle-shipping experience by combining innovative logistical solutions with our team's dedication to delighting customers.
                    </p>
                </div>
            </div>

        </div>
        <div class="row three-section">
            <div class="col-lg-6 align-self-center">
                <div class="wthree_pvt_title  mb-3">
                    <span class="sub-title">Because we remain...</span>
                    <h4 class="w3pvt-title">Building on our values
                    </h4>

                </div>
                <div class="single_grid_text pt-lg-3">
                    <p>Our organization strives for leadership, and we are not hesitant to make big actions in order to enhance procedures and communication.
                        When working with a AQ Movers team member, operating with integrity promotes a true feeling of mutual trust, honesty, and transparency.
                        We remain technologically savvy, believing that AQ Movers's clients and partners deserve to benefit from the most cutting-edge technology available.</p>
                </div>
            </div>
            <div class="col-lg-6 mt-lg-0 mt-4">
                <div class="slide-img-2">
                    <img src="../../assets/images/g3.jpg" class="img-fluid" alt="" />
                </div>
            </div>
        </div>

    </div>
</section>
<!-- //about -->

<!-- about-->
<section class="single_grid_w3_main align-w3">
    <div class="container">
        <div class="wthree_pvt_title text-center">
            <h4 class="w3pvt-title">Who We Are & What We Do</h4>
            <p class="sub-title">AQ Movers is one of the leading automobile transporters in the country, with head office located in Dallas, TX. We have locations and warehouses in major metropolitan areas and urban regions across the United States and internationally, as well as a team of experienced shipping specialists to ensure that you receive the service you need at a price you'll enjoy. This website is geared to assisting you in comprehending who we are and what we do.</p>
        </div>
        <div class="decades">
            <div class="decad-img">
                <img src="../../assets/images/g3.jpg" alt="img" class="img-fluids">
            </div>
           
            <div class="align-self-center">
            <h2>About AQ Movers.</h2>
            <p> AQ Movers has decades of experience and knowledge in the transportation sector.</p>
            <p>We know how to navigate the procedure and make it as simple as possible for you as one of the leading US-based global vehicle shippers and movers in the market, with offices in key cities around the country. For us, it's about more than just transportation of your vehicle. We work hard to leave a good influence on the communities we deal with as industry experts, providing you with all the information you need so you can enjoy the best possible market competitive pricing. We'll help you get your vehicle shipped, on schedule and on budget, whether it's domestic or international.</p>
            <p>While we totally understand that it can be quite challenging to negotiate in the vehicle transport market which is why our objective is to make your experience as straightforward as possible. No hidden fees, no jargon, just straightforward shipping from your pick-up location to your drop off location. Our service are very broad and range from automobiles to trucks, boats, motorcycles and RVs – whatever the occasion demands, we've got you covered.</p>
        </div>
    </div>

    </div>
</section>
<section class="single_grid_w3_main align-w3">
    <div class="container">
        <div class="wthree_pvt_title text-center">
            <h4 class="w3pvt-title">Our Core Values</h4>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="abt-grid">
                    <div class="row">
                        <div class="col-3">
                            <div class="abt-icon">
                                <span>
                                    <img src="../../assets/images/authority.png" alt="img" width="50px">
                                </span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="abt-txt">
                                <h4>Authority</h4>
                                <p>It's something we've seen and experienced throughout the years. Because of our expertise, we know what works and how to get you rolling so you can get your vehicle shipped without the hassle.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-lg-0 my-4">
                <div class="abt-grid">
                    <div class="row">
                        <div class="col-3">
                            <div class="abt-icon">
                                <span>
                                    <img src="../../assets/images/expert.png" alt="img" width="50px">
                                </span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="abt-txt">
                                <h4>Expertise</h4>
                                <p>The shipping process is lengthy and often cumbersome. We ensure to guide you through even the most difficult situations thanks to our 3 decades of experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="abt-grid">
                    <div class="row">
                        <div class="col-3">
                            <div class="abt-icon">
                                <span>
                                    <img src="../../assets/images/display.png" alt="img" width="50px">
                                </span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="abt-txt">
                                <h4>Transparency</h4>
                                <p>In a sector rife with dubious businesses, we take pleasure in being open, transparent and honest. Our consumers are kept well informed at all times, and our quotes are highly competitive.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-lg-5 pt-4">
            <div class="col-lg-4">
                <div class="abt-grid">
                    <div class="row">
                        <div class="col-3">
                            <div class="abt-icon">
                                <span class="fa fa-clock-o"></span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="abt-txt">
                                <h4>Caption</h4>
                                <p>Lo sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-lg-0 my-4">
                <div class="abt-grid">
                    <div class="row">
                        <div class="col-3">
                            <div class="abt-icon">
                                <span class="fa fa-pencil"></span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="abt-txt">
                                <h4>Caption</h4>
                                <p>Lo sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="abt-grid">
                    <div class="row">
                        <div class="col-3">
                            <div class="abt-icon">
                                <span class="fa fa-ravelry"></span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="abt-txt">
                                <h4>Caption</h4>
                                <p>Lo sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- //about -->

<!-- services -->
<section class="single_grid_w3_main align-w3">
    <div class="container">
        <div class="wthree_pvt_title text-center">
            <h4 class="w3pvt-title">The AQ Movers Philosophy</h4>
            <p class="sub-title">We believe that it’s more than simply transporting your vehicle; it's about doing it right the first time and every time afterwards. We prioritize getting your job done correctly, which means we never cut shortcuts. Our clients gain from our market authority, experience, and honest procedures when they collaborate with us to ship their vehicles.</p>
        </div>
        <div class="decades">
           
           
            <div class="align-self-center">
            <h2>How do we ensure that?</h2>
           <ul>
               <li>We only work with the most trustworthy trucking companies that share our beliefs as your shipments are essential to our success, and our success stories inspire us to keep developing year after year.</li>
               <li>We keep you up to date throughout the whole process by explaining everything. The more information you have, the faster and more enjoyable the shipping procedure will be.</li>
               <li>Our pricing is most competitive while ensuring that we do not sacrifice quality.</li>
           </ul>
        </div>
        <div class="decad-img-one text-center">
            <img src="../../assets/images/m1.jpg" alt="img" class="img-fluids">
        </div>
    </div>

    </div>
</section>
<section >
    <div class="container">
        <div class="career">
            <h1>Careers</h1>
            <p>Are you interested in becoming a member of the AQ Movers team? We are constantly hiring talented individuals as the company grows to help us achieve new milestones. It’s ambitious people like you that make AQ Movers what it is today. We’re constantly hiring talented:</p>
        </div>
        <div class="all-careers">
            <div class="col-lg-3 each-career">
                <img src="../../assets/images/truck.png" alt="truck">
                <h6>Truck Drivers</h6>
            </div>
            <div  class="col-lg-3  each-career">
                <img src="../../assets/images/customer-service.png" alt="customer-service">
                <h6>Customer Service</h6>
            </div>
            <div  class="col-lg-3  each-career">
                <img src="../../assets/images/sales.png" alt="Sales & Marketing">
                <h6>Sales & Marketing</h6>
            </div>
            <div  class="col-lg-3 each-career">
                <img src="../../assets/images/website.png" alt="Web Technology">
                <h6>Web Technology</h6>
            </div>
        </div>
        <div class="career">
            <h5>If you’re interested in working with us, contact us and include the department you wish to apply for:</h5>
            <button> <a href="mailto:contact@aqmovers.com" target="_blank">contact@aqmovers.com</a></button>
            <!-- <button>internships@a1autotransport.com</button> -->
            <p>Students interested in working in the vehicle transport sector can also apply for internships with us. Internships in marketing, sales, and customer service are the most common. Please contact us with any internship queries.</p>
        </div>
       
    </div>
   
</section>
<!-- <section class="bg-services position-relative align-w3" id="services">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="services-bg-color">
                    <div class="wthree_pvt_title mb-3">
                        <h4 class="w3pvt-title w3pvt-titl-sec text-wh">what we provide
                        </h4>
                        <span class="sub-title text-li">add your caption here</span>
                    </div>
                    <div class="row">
                        <div class="col-md-6 service-title my-4">
                            <h4 class="home-title text-theme">Sub heading</h4>
                            <p class="sec-4">Itaque earum rerum hic tenetur a sapiente delectusum hic
                                tenetur yua.
                            </p>
                        </div>
                        <div class="col-md-6 service-title my-md-4">
                            <h4 class="home-title text-theme">Sub heading</h4>
                            <p class="sec-4">Itaque earum rerum hic tenetur a sapiente delectusum hic
                                tenetur ap.
                            </p>
                        </div>
                        <div class="col-md-6 service-title mt-4">
                            <h4 class="home-title text-theme">Sub heading</h4>
                            <p class="sec-4">Itaque earum rerum hic tenetur a sapiente delectusum hic
                                tenetur ar.
                            </p>
                        </div>
                        <div class="col-md-6 service-title mt-4">
                            <h4 class="home-title text-theme">Sub heading</h4>
                            <p class="sec-4">Itaque earum rerum hic tenetur a sapiente delectusum hic
                                tenetur as.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start">
                        <a href="about.html" class="btn w3ls-btn">view more</a>
                    </div>
                </div>

            </div>
            <div class="offset-lg-2"></div>
        </div>
    </div>
</section> -->
<!-- //services -->

<!-- team -->
<!-- <section class="w3layouts_hubits-services align-w3" id="team">
    <div class="container">
        <div class="wthree_pvt_title text-center">
            <h4 class="w3pvt-title">our experts
            </h4>
            <p class="sub-title">Subheadings stand out because they are like mini titles. They make your post stand
                out and
                make it more readable.</p>
        </div>
        <div class="row py-4 mt-lg-5  team-grid">
            <div class="col-md-4 col-sm-6">
                <div class="box13">
                    <img src="../../assets/images/t1.jpg" class="img-fluid" alt="" />
                    <div class="box-content">
                        <h3 class="title">William</h3>
                        <span class="post">role in detail</span>
                        <ul class="social">
                            <li><a><i class="fa fa-facebook"></i></a></li>
                            <li><a><i class="fa fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="box13">
                    <img src="../../assets/images/t2.jpg" class="img-fluid" alt="" />
                    <div class="box-content">
                        <h3 class="title">Kriss</h3>
                        <span class="post">role in detail</span>
                        <ul class="social">
                            <li><a><i class="fa fa-facebook"></i></a></li>
                            <li><a><i class="fa fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 mx-auto">
                <div class="box13">
                    <img src="../../assets/images/t3.jpg" class="img-fluid" alt="" />
                    <div class="box-content">
                        <h3 class="title">James</h3>
                        <span class="post">role in detail</span>
                        <ul class="social">
                            <li><a><i class="fa fa-facebook"></i></a></li>
                            <li><a><i class="fa fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section> -->
<!-- //team -->