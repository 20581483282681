<div class="mt-70">
    <div class="inner-banner-1">
        <app-booking-request></app-booking-request>
    </div>
    <section class="container mt-4">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-7">
                <h1>Car Shipping California</h1>
                <p>
                    With nearly 40 million citizens, California is America's most
                    populous state. If California were its own country, as some say, it
                    would have the world's sixth-largest economy. That implies there are
                    many car transport shipments in California.
                </p>
                <p>
                    Every year, thousands, if not millions, of people relocate to
                    California. California is one of the most popular states to relocate
                    to because of its Hollywood and celebrity culture, stunning
                    landscape, favorable weather, various communities, and enormous
                    beaches many people seek to live in California.
                </p>
                <div [ngClass]="{ limitTextHeight: isReadMore }">
                    <p>
                        Many people also move from California to another state as the
                        expense of living in this state is relatively high, yet strangely,
                        it is ranked third-worst in the country for having the worst
                        roads. This is due to the state's ever-increasing population and
                        the increased number of cars per family, producing service traffic
                        in many regions.
                    </p>

                    <p>
                        Moving to and from California can be a difficult task. You begin
                        the moving procedure once you have found your new home. You must
                        also ship your car along with your other items. Hiring the Best
                        Car Shipping Company is the one thing that can help you simplify
                        the car shipping procedure.
                    </p>
                </div>
                <div class="mb-3 mt-2 btnCenter">
                    <button type="button" (click)="showText()" id="myBtn" class="bgCOLOR1">
                        Read more
                    </button>
                </div>
            </div>

            <div class="col-lg-5">
                <img src="../../assets/images/states-images/California/Car Shipping California.png" alt="img" />
            </div>
        </div>
    </section>

    <section class="container s5">
        <div class="row">
            <div class="col-lg-5">
                <img src="../../assets/images/states-images/California/Best Car Shipping Company Near me in California.png" alt="img" class="w-100" />
            </div>
            <div class="col-lg-7 col-md-7 col-xl-7 col-xxl-7 col-sm-12 col-12 setPadding1">
                <h3>
                    Best Car Shipping Company Near me in California
                </h3>

                <p>
                    So, are you looking for the best car shipping company to ship your
                    car to or from California? AQ Movers is one of the
                    best car companies in California, and we can help you whether you
                    need a car shipped to California or from California to another
                    state. We can reliably pick up and transport vehicles safely and
                    cost-effectively with carriers running throughout California.
                </p>
                <p>
                    We are passionate about providing quality car transportation
                    services in California. We provide open, enclosed, and door-to-door
                    services for California car shipping. We conduct a great deal of
                    work in California's major cities, such as Sacramento and San
                    Francisco, and down south in Los Angeles and San Diego.
                </p>
                    <p>
                        AQ Movers provides simplicity and effectiveness
                        in our service by removing the need for our clients to engage with
                        several parties. Our mission is to deliver great service while
                        meeting our clients' transportation demands.
                    </p>
            </div>
        </div>
    </section>

    <section class="container mt-5 mb-5 text-center">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <h2 class="text-grey">10,452 +</h2>
                <a>Satisfied Customers</a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <h2 class="text-grey">16+ Years</h2>
                <a>Experience</a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <h2 class="text-grey">20,000+</h2>
                <a>Drivers</a>
            </div>
        </div>
    </section>

    <section class="container s5">
        <div class="row">
            <div class="col-lg-7">
                <h3>
                    Affordable Car Shipping Company in California
                </h3>

                <p>
                    Here at AQ Movers, we do our best to give our
                    customers the best prices. We offer the most competitive car
                    shipping costs based on the automobile relocation you receive. Your
                    vehicle will be delivered to and from California by skilled
                    professionals at a reasonable cost.
                </p>
                <p>
                    Our car shipping rate assurance service locks in your pricing when
                    you reserve your car transportation service. AQ Movers
                    Solutions assures that your quote will not change regardless of any
                    increase in transportation expenses between the time of booking and
                    shipment. We promise to do our best to provide the highest quality
                    services and most affordable prices.
                </p>
            </div>
            <div class="col-lg-5">
                <img src="../../assets/images/states-images/California/Affordable Car Shipping Company in California.png" alt="img" class="w-100" />
            </div>
        </div>
    </section>
    <section class="container s5">
        <div class="row">
            <div
                class="col-lg-5">
                <img src="../../assets/images/states-images/California/How much does Car Shipping To-From California Cost.png" alt="img" class="w-100" />

            </div>
            <div class="col-lg-7">
                <h3>
                    How much does Car Shipping To/From California Cost?
                </h3>
                <p>
                    When estimating the cost of shipping a car to or from California,
                    several things must be considered. The number of miles determines it
                    from the pick-up place, the size and kind of vehicle, the season
                    when most people travel or least, the time of year, the type of
                    terrain encountered along the way, and other factors.
                </p>
                <p>
                    Use our online calculator to get a free and instant quote for
                    shipping a car to or from California.
                </p>
            </div>
        </div>
    </section>

    <section style="background-color: #f5e4ed">
        <div class="container rounded">
            <div class="mt-5">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-6 mt-4">
                        <h3>
                            Long-Distance Car Auto Transport
                        </h3>
                        <p>
                            Our shippers offer long-distance car transportation and nearby
                            cities' car shipping. We strive to maintain a high level of
                            client satisfaction whether you transport a car to California or
                            from California to another state. AQ Movers
                            has offered nationwide car shipping for short and long
                            distances.
                        </p>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <h3>
                            Our Most Popular California Car Transportation Routes
                        </h3>
                        <p>
                            Many of our California clients move vehicles from Los Angeles,
                            Bakersfield, San Diego, San Francisco, Oakland, Holly Wood, San
                            Jose, and Pasadena to Arizona, Texas, New Mexico, Nevada,
                            Washington, North Carolina, Florida, and Montana.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section style="background-color: #f5e4ed">
        <section class="s11 container-fluid mt-0 checkthis text-white">
            <div class="p-3">
                <div class="text-center">
                    <h2 class="text-white">
                        What Car Shipping Services We Offer to and from in California?
                    </h2>
                </div>
            </div>
            <div class="row setPadding1">
                <div class="col-lg-6 borderRightc">
                    <h3 class="text-white">Enclosed Auto Transport to California</h3>
                    <div class="text-white">
                        <p class="text-white">
                            The arid climate is always a factor to consider while shipping
                            your car to and from California. Furthermore, the state's hot,
                            dry atmosphere can cause paint to fade quickly. Dust storms,
                            which are common in arid climates, can also harm the exterior of
                            your car.
                        </p>
                        <p class="text-white">
                            As a result, shipping your car to California in enclosed auto
                            transport is a smarter investment, as it will protect it from
                            the harsher features of California's environment. AQ Movers
                            Transport solutions offer you <a class="text-white" href="/enclosed-autotransport"><b>enclosed auto transport</b></a> as to
                            transport your vehicle safely is our priority. Enclosed auto
                            transport is the safest way to transport your car to California.
                        </p>
                        <div class="d-flex justify-content-center align-items-center mb-3">
                            <div class="d-flex justify-content-center">
                                <div >
                                    <a class="btn d-none d-lg-block Call text-nowrap colorDark"
                                        href="tel:+1 (339) 237-3082">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-telephone d-xs-none" viewbox="0 0 16 16">
                                            <path
                                                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                        (339) 237-3082
                                    </a>
                                </div>
                                <div class="ms-1">
                                    <button class="btn text-nowrap bgCOLOR1" (click)="getQuote()">
                                        Get a Quote
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p class="text-white">
                            When exporting your car via enclosed auto transport, we have two
                            options
                        </p>
                        <h5 class="text-white">Soft-Sided Enclosed Auto Transport —</h5>
                        <p class="text-white">
                            The strong fabric that covers the carriers protects your car
                            from wind-blown debris, rain, sleet, and snow, among other
                            things.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 d-block justify-content-center align-items-center col-md-12 col-xl-6 col-xxl-6 col-sm-12 col-12 transx">
                    <div class="text-center mt-4 mt-lg-0">
                        <img src="../../assets/images/states-images/car-auto-transport.jpg" alt="img"
                            class="w-100" />
                    </div>

                    <h5 class="text-white">Hard-Sided Enclosed Auto Transport —</h5>
                    <p class="text-white">
                        In addition to the benefits afforded by soft-sided carriers,
                        hard-sided carriers protects your vehicle in the unlikely event
                        that it sustains damage during transit.
                    </p>
                </div>
                <section >
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-5 p-xl-5">
                            <img src="../../assets/images/states-images/California/Open Auto Transport to California.png" alt="img" class="w-100" />
                            <div class="p-3">
                                <h3 >Open Auto Transport to California</h3>
                                <p class="text-white">
                                    AQ Movers also offers <a class="text-white" href="/open-vehicle-transport"><b>Open Auto Transport</b></a>
                                    to California Service. Open auto transport car carriers are
                                    the most cost-effective and cheap car hauler services in
                                    California we offer. Even though your vehicle will be
                                    exposed to the elements during auto shipping, no tires will
                                    touch the ground until complete delivery.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-5 p-xl-5">
                            <img src="../../assets/images/states-images/California/Luxury Vehicle Transportation.png" class="w-100" alt="img" />
                            <div class="p-3">
                                <h3 class="text-white">Luxury Vehicle Transportation</h3>
                                <p class="text-white">
                                    When you’re moving something as valuable as your luxury car,
                                    you want to know it’s in the right hands. AQ Movers
                                    Solutions is your best option for reliable, professional,
                                    and secure luxury vehicle shipping services. We Specialize
                                    in Luxury Vehicle Transportation.
                                </p>
                            </div>
                        </div>
                    </div>
                    <section class="bg-services position-relative align-w3" id="services">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="services-bg-color text-center justify-content-between">
                                <div class="title align-self-center">
                                  <h3>35% OFF For the next 24 hours</h3>
                                </div>
                                <div class="looking-btn col-lg-5">
                                  <a class="btn Call" id="btn-quote" href="tel:+1 (339) 237-3082"> <i class="fa fa-phone"></i>(339) 237-3082
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </section>
                    <div class="row p-5 d-flex justify-content-center">
                        <div class="col-lg-5 align-items-center my-auto justify-content-center">
                            <img src="../../assets/images/states-images/California/Door-to-Door Car Shipping to Montana.png" alt="img" class="w-100" />
                        </div>
                        <div class="col-lg-6">
                            <h3 class="text-white">Door-to-Door Car Shipping to Montana</h3>
                            <p class="text-white">
                                AQ Movers offers <a class="text-white" href="/Door-to-door-auto-transport"><b>door-to-door auto-shipment service</b></a>
                                from any residential or commercial site in Montana.
                                Before your car is picked up for shipping from Montana, it
                                will be thoroughly inspected to record its current condition
                                and safely loaded onto the car carrier, as it is now ready for
                                transport.
                            </p>
                            <p class="text-white">
                                Our door-to-door <a class="text-white" href="/car-shipping-service"><b>car shipping service</b></a> is by far the most
                                convenient. We can easily transport an automobile to any place
                                in Montana, so don't be afraid to supply us with the pick-up
                                or delivery address once you receive our low-cost price.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </section>
    <section style="background-color: #f5e4ed">
        <section class="container text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-7 col-md-7 col-xl-7 col-xxl-7 col-sm-12 col-12 setPadding1">
                    <h2 class="text-center">
                        Stress-Free Car Shipping Service in California
                    </h2>
                    <p>
                        Driving is particularly popular in California, as most residents
                        rely on cars to navigate around the state. And Car shipping is
                        convenient, simple, and stress-free with AQ Movers
                        Solutions!
                    </p>
                    <p>
                        Regardless of the type of vehicle you need to shipped, QTS has the
                        equipment and licenses to do so. We can ship any of those
                        automobiles and others anywhere in or out of California. So, don't
                        be concerned about petrol prices, mileage, or car wear and tear on
                        your car due to traveling. We will pick up your car from your home
                        or office and drop it off at your specified place.
                    </p>

                    <a class="btn bgCOLOR1" (click)="getQuote()"> Get a Quote </a>
                </div>

                <div class="col-lg-5 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/states-images/California/Stress-Free Car Shipping Service in California.png" alt="img" class="w-100" />
                </div>
            </div>
        </section>
    </section>

    <section class="container s5">
        <div class="row">
            <div class="col-lg-7 col-md-7 col-xl-7 col-xxl-7 col-sm-12 col-12 setPadding1">
                <h3>
                    How long does Auto Shipping To/From California Take?
                </h3>

                <p>
                    Car shipping to/from California is much faster and takes only a
                    couple of days for shorter distances, less than 200 miles. However,
                    vehicle shipping to/from California can take up to 9 days for
                    distances more than 2,000 miles. But don't be concerned; we'll do
                    everything to ensure you enjoy a seamless, speedy, and hassle-free
                    service.
                </p>
                <p>
                    Several factors can influence how long it takes car carriers in
                    California to transport your vehicle. Weather, distance, driver
                    hours, road construction, traffic, detours, and seasonality can all
                    impact auto shipping to and from California. If you're in a hurry,
                    you can ask for our guaranteed pick-up or expedited shipping.
                </p>
            </div>
            <div
                class="col-lg-5 col-md-5 col-xl-5 col-xxl-5 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                <img src="../../assets/images/states-images/California/How long does Auto Shipping To-From California Take.png" alt="img" class="w-100" />
            </div>
        </div>
    </section>
    <section class="container-fluid mt-5">
        <div class="row centerForMedium">
            <div class="col-lg-4 transx mb-auto text-center d-none d-lg-block col-md-3 col-xl-3 col-xxl-3 border-right border-top rounded"
                style="background-image: linear-gradient(to left, #533645, #502433)">
                <h3
                    class="text-left mt-1 ms-0 d-flex justify-content-center text-white p-3 border-top border-right border-bottom">
                    Our Other Services
                </h3>
                <div class="card m-0 text-center" style="
            background-image: linear-gradient(to left, #533645, #502433);
            border: none;">
                    <a href="/Motorcycle-Shipping">
                        <img class="card-img-top px-5 px-5 d-flex justify-content-center"
                            src="../../assets/images/states-images/California/Car Shipping to or from California.png" alt="Card image cap" />
                    </a>
                    <div class="text-center card-body">
                        <h5 class="card-title text-nowrap text-center text-white"
                            style="opacity: 1; padding: 10px; opacity: 0.9">
                           <a href="/Motorcycle-Shipping">Motorcycle Shipping</a> 
                        </h5>
                        <p class="card-text" style="opacity: 1"></p>
                        <a class="btn text-nowrap bgCOLOR1" (click)="getQuote()">
                            Get a Quote
                        </a>
                    </div>
                </div>

                <div class="card m-0 px-5 d-flex justify-content-center" style="
                        background-image: linear-gradient(to left, #533645, #502433);border: none;">
                    <a href="/car-shipping-service">
                        <img class="card-img-top" src="../../assets/images/states-images/California/Some Other Ways to find our California Car Shipping Company.png"
                            alt="Card image cap" /></a>
                    <div class="card-body">
                        <h5 class="card-title text-nowrap text-white" style="opacity: 1; padding: 10px; opacity: 0.9">
                          <a href="/car-shipping-service">Car Shipping</a> 
                        </h5>
                        <p class="card-text" style="width: 18rem; opacity: 1"></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-9 col-xl-9 col-xxl-9 col-sm-9 col-12 mt-3">
                <div>
                    <h3 class="stylishBorder text-center">
                        Car Shipping to or from California:
                    </h3>
                    <div class="text-center"></div>
                    <div class="row p-lg-3 d-flex justify-content-center">
                        <div class="col-lg-7 col-md-7 col-xl-7 col-xxl-7 col-sm-12 col-12">
                            <p>
                                AQ Movers wants to be one of the leading
                                California auto shipping companies in the United States by
                                delivering exceptional customer service, cheap shipping rates,
                                passionate agents, and a dedicated network of carriers. With
                                thousands of returning customers, AQ Movers
                                is your one-stop-shop for vehicle shipping, no matter where
                                you need it sent in the United States.
                            </p>
                            <p>
                                AQ Movers can help you with car shipping to
                                or from California right now. All you have to do is request a
                                free quote, and we'll handle the rest. Please complete our
                                instant car shipping quote form or discover why thousands of
                                shippers rely on our California Car shipping service every
                                day.
                            </p>
                            <div class="mt-5 d-flex justify-content-center align-items-center mb-3">
                                <div class="d-flex justify-content-center">
                                    <div >
                                        <a class="btn d-none d-lg-block Call text-nowrap colorDark"
                                            href="tel:+1 (339) 237-3082">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-telephone d-xs-none"
                                                viewbox="0 0 16 16">
                                                <path
                                                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            </svg>
                                            (339) 237-3082
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <img src="../../assets/images/states-images/California/Car Shipping to or from California.png" class="w-100" alt="img" />
                        </div>
                    </div>
                </div>

                <div class="container">
                    <h4>
                        Some Other Ways to find our California Car Shipping Company:
                    </h4>
                    <div class="row d-flex justify-content-center">
                        <div class="p-0 col-lg-6 col-md-7 col-xl-5 col-xxl-5 col-sm-12 col-12">
                            <img src="../../assets/images/states-images/truck4.jpg" class="w-100"
                                alt="img" />
                            <ul class="fw-light list-unstyled rounded-list p-0 p-lg-5" style="font-size: 20px">
                                <li><a>Car Transport California</a></li>
                                <li><a> Shipping a Car California</a></li>
                            </ul>
                        </div>

                        <div
                            class="col-lg-6 col-md-7 col-xl-7 col-xxl-7 col-sm-12 col-12 px-1 pt-lg-2 align-items-center">
                            <div >
                                <ul class="list-unstyled fw-light rounded-list p-0 p-lg-5" style="font-size: 20px">
                                    <li><a>Car Ship California</a></li>
                                    <li><a> Auto Transport California</a></li>

                                    <li><a> Car Transportation California</a></li>

                                    <li><a> Auto Shipping Companies California</a></li>

                                    <li><a> Car Shipping California</a></li>
                                    <li><a> Transport Car California</a></li>
                                    <li><a> Car Transporter California</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container">
        <div class="w-100 align-w3 py-3">
            <div >
                <span class="sub-title">Frequently Asked Question</span>
                <h4 class="w3pvt-title">Have Any Question?</h4>
            </div>
            <div class="accordion faq" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button acrd-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            How to ship a car to another state?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div itemprop="text">
                                <p>
                                    When considering how to transport an automobile to another
                                    state, all you want is a cost-effective, quick, safe, and
                                    secure method of having your car picked up from its current
                                    location and dropped off at a new place in another state in
                                    the shortest amount of time. For this, you must select
                                    AQ Movers Travel Solutions; we will assist you with interstate
                                    car transport.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Can I ship my car to California?
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div itemprop="text">
                                <p>
                                    Yes, you can ship your car to California easily with AQ Movers
                                    Transport Solutions. We are the California car shipping
                                    company clients trust. Our auto shipping specialists are
                                    industry experts who provide industry-leading customer
                                    service. Every team member will have the utmost respect and
                                    honesty for you and your vehicle, from offering upfront
                                    quotes and pricing to working with you throughout the
                                    transportation process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            What is the process of shipping a car to California?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div itemprop="text">
                                <p>
                                    When you ship with AQ Movers, shipping a
                                    car to California is as simple as 1-2-3.
                                </p>

                                <p>
                                    Step 1: Get a quick auto shipping quote and schedule a
                                    pick-up time that works for you.
                                </p>

                                <p>
                                    Step 2: Your vehicle gets picked up from a specified
                                    location anywhere in the United States.
                                </p>
                                <p>
                                    Step 3: Your vehicle is delivered to the place you specify
                                    in California.
                                </p>
                                For further details and information, Call us at
                                <b> <a href="tel:+1 (339) 237-3082">(339) 237-3082</a> </b>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            Should I make an advanced car transport booking? How far ahead?
                        </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div itemprop="text">
                                <p>
                                    Yes, if possible, you should book advanced vehicle shipping
                                    because it will save you time and trouble. Last-minute
                                    bookings may create delays because we must organize a
                                    carrier at the last minute. If you book a week in advance,
                                    we will have enough time to match you with the finest
                                    carrier, and you will have enough time to prepare your car
                                    for shipping.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>