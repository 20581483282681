import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiple-vehicles-transport',
  templateUrl: './multiple-vehicles-transport.component.html',
  styleUrls: ['./multiple-vehicles-transport.component.scss']
})
export class MultipleVehiclesTransportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0)
  }

}
