import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-shipping-across-country',
  templateUrl: './vehicle-shipping-across-country.component.html',
  styleUrls: ['./vehicle-shipping-across-country.component.scss']
})
export class VehicleShippingAcrossCountryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0)
  }

}
