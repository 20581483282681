import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landingpage-auto',
  templateUrl: './landingpage-auto.component.html',
  styleUrls: ['./landingpage-auto.component.scss']
})
export class LandingpageAutoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
