<div class="mt-70">
    <div class="inner-banner-1">
        <app-booking-request></app-booking-request>
    </div>

    <section class="container">
        <div class="container">
            <div class="text-left mb-4">
                <h1 class="colorWhite">Why Choose US?</h1>
                <div style="width: 300px; background-color: white; height: 2px"></div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 cardi m-auto" style="border-right: 3px white solid">
                    <h3 class="text-center d-flex align-items-center" style="
                                font-size: 20px !important;
                                height: 30%;
                                width: 100%;
                                border-right: 3px white solid;">
                        Zero Upfront Payment:
                    </h3>
                    <p style="font-size: 14px !important">
                        We do not ask for any upfront payment, you only pay the price when
                        your car is shipped to your location. So, $0 upfront payment for
                        the transport of your car.
                    </p>
                </div>
                <!-- <div class="col-lg-1 cardionic"></div> -->
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 cardi m-auto" style="border-right: 3px white solid">
                    <h3 class="text-center d-flex align-items-center" style="
              font-size: 20px !important;
              height: 30%;
              width: 100%;
              border-right: 3px white solid;
            ">
                        Top-Rated Auto Transport:
                    </h3>
                    <p style="font-size: 14px !important">
                        AQ Movers Solution is the best and top-rated shipping
                        company not only in Florida but in the whole country. Our maximum
                        5-star rating is proof of our quality and professional service.
                    </p>
                </div>
                <!-- <div class="col-lg-1 cardionic"></div> -->
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 cardi m-auto" style="border-right: 3px white solid">
                    <h3 class="text-center d-flex align-items-center" style="
              font-size: 20px !important;
              height: 30%;
              width: 100%;
              border-right: 3px white solid;
            ">
                        Complete Insurance Coverage:
                    </h3>
                    <p style="font-size: 14px !important">
                        We offer complete insurance coverage for your car shipping to
                        Florida, to ensure maximum safety for your transport.
                    </p>
                </div>
                <!-- <div class="col-lg-1 cardionic"></div> -->
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 cardi m-auto">
                    <h3 class="text-center d-flex align-items-center" style="
              font-size: 20px !important;
              height: 30%;
              width: 100%;
              border-right: 3px white solid;
            ">
                        24/7 Customer Support:
                    </h3>
                    <p style="font-size: 14px !important">
                        To provide proper guidance and information to our customers,
                        AQ Movers provides 24/7 customer support all over the
                        year.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section  class="container bg-smokey mt-4">
        <div class="best-florida">
            <h2 class="text-center">Best Florida Transport Services Near Me</h2>
            <p>
                Is it difficult to find the best Florida transport near me for
                shipping your car? Worry not, AQ Movers solution is here
                to offer you the best <a href="/car-shipping-service"><b>car shipping services</b></a> in Florida.
                Whether
                you are moving to Florida, a seasonal traveler, or a military
                family you may want to ship your car through a reliable and
                efficient <a href="/car-shipping-service"><b>car shipping services</b></a> .
            </p>
        </div>
    </section>
    <div class="bg-white" class="mt-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center">
                    <img src="../../assets/images/states-images/Florida/Best Florida Transport Services Near Me.png" class="w-100" alt="img" />
                </div>
                <div class="col-lg-6 align-self-center">
                    <p>
                        We are industry professionals with years of experience in
                        shipping all types of vehicles to and from Florida. AQ Movers
                        Transport has the required equipment, people, and experience
                        to ship your car safely to whatever location you are willing
                        to ship.
                    </p>
                    <a class="btn text-nowrap bgCOLOR1" (click)="getQuote()">
                        Get a Quote
                    </a>
                </div>
            </div>
        </div>
    </div>
        <section class="container">
            <div>
                <h2 class="text-center pt-5">
                    Some Tips For Using Car Shipping Florida
                </h2>
                <section class="row mt-5 justify-content-center">
                    <div class="ownCard changeWidth mt-0">
                        <img src="../../assets/images/states-images/Florida/Pick-Up Point.png" alt="img" />
                        <h3>Pick-Up Point</h3>
                        <p>
                            Choose a pick-up point that is close to your visiting location:
                            Since there are several areas to visit in the state, you must
                            ensure to make your auto shipping Florida ship your car to a
                            location that is close to your visiting area.
                        </p>
                        <p>
                            This will save you both the time and effort for bringing your
                            car to your visiting location from a far-off pick-up point.
                        </p>
                    </div>
                    <div class="ownCard changeWidth px-5 mt-2">
                        <img src="../../assets/images/states-images/Florida/Protect your vehicle from external damage.png" alt="img" />
                        <h3>Proper Inspection</h3>
                        <p>
                            Choose a pick-up point that is close to your visiting location:
                            Since there are several areas to visit in the state, you must
                            ensure to make your auto shipping Florida ship your car to a
                            location that is close to your visiting area.
                        </p>
                        <p>
                            This will save you both the time and effort for bringing your
                            car to your visiting location from a far-off pick-up point.
                        </p>
                    </div>
                    <div class="ownCard changeWidth mt-4">
                        <img src="../../assets/images/states-images/Florida/Professional Shipper.png" alt="img" />
                        <h3>Professional Shipper</h3>
                        <p>
                            Choose a pick-up point that is close to your visiting location:
                            Since there are several areas to visit in the state, you must
                            ensure to make your auto shipping Florida ship your car to a
                            location that is close to your visiting area.
                        </p>
                        <p>
                            This will save you both the time and effort for bringing your
                            car to your visiting location from a far-off pick-up point.
                        </p>
                    </div>
                    <div class="ownCard changeWidth mt-0">
                        <img src="../../assets/images/states-images/Florida/Get Multiple Quotes.png" alt="img" />
                        <h3>Get Multiple Quotes</h3>
                        <p>
                            Choose a pick-up point that is close to your visiting location:
                            Since there are several areas to visit in the state, you must
                            ensure to make your auto shipping Florida ship your car to a
                            location that is close to your visiting area.
                        </p>
                        <p>
                            This will save you both the time and effort for bringing your
                            car to your visiting location from a far-off pick-up point.
                        </p>
                    </div>
                    <div class="ownCard changeWidth mt-2">
                        <img src="../../assets/images/states-images/Florida/Protect your vehicle from external damage.png" alt="img" />
                        <h3>Protect your vehicle from external damage:</h3>
                        <p>
                            Choose a pick-up point that is close to your visiting location:
                            Since there are several areas to visit in the state, you must
                            ensure to make your auto shipping Florida ship your car to a
                            location that is close to your visiting area.
                        </p>
                            <p>
                                This will save you both the time and effort for bringing your
                                car to your visiting location from a far-off pick-up point.
                            </p>
                    </div>
                    <div class="ownCard changeWidth mt-4">
                        <img src="../../assets/images/states-images/Florida/Professional Shipper.png" alt="img" />
                        <h3 class="mt-3">Plan the Trip</h3>
                        <p>
                            Choose a pick-up point that is close to your visiting location:
                            Since there are several areas to visit in the state, you must
                            ensure to make your auto shipping Florida ship your car to a
                            location that is close to your visiting area.
                        </p>
                            <p>
                                This will save you both the time and effort for bringing your
                                car to your visiting location from a far-off pick-up point.
                            </p>
                    </div>
                </section>
            </div>
        </section>

    <section class="bg-services position-relative align-w3" id="services">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="services-bg-color text-center justify-content-between">
                <div class="title align-self-center">
                  <h3>35% OFF For the next 24 hours</h3>
                </div>
                <div class="looking-btn col-lg-5">
                  <a class="btn Call" id="btn-quote" href="tel:+1 (339) 237-3082"> <i class="fa fa-phone"></i>(339) 237-3082
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section  class="container bg-smokey mt-4">
        <div class="best-florida">
            <h2>Long-Distance Vehicle Transport Florida</h2>
            <p class="d-none d-lg-block">
                Car shipping to a long distance is quite common in the car
                shipping industry. Therefore, AQ Movers Solution offers
                the required staff and equipment to ship your car to your
                location, no matter how far it is. We are industry experts with
                years of experience in shipping a car to Florida from any far-off
                location of the country with safety and efficiency.
            </p>
        </div>
    </section>
        <section class="container mt-5">
                <div class="row">
                    <div class="col-lg-6 align-self-center">
                        <p>
                            When shipping a car to a long distance, there are several
                            challenges that a car shipping company Florida faces. AQ Movers
                            Transport Solution hires only those professionals and shipping
                            experts who know how to tackle those challenges safely. For
                            shipping your car to a far-off location, we offer open and
                            enclosed vehicle transportation to meet your demands.
                        </p>
                        <p>
                            Car shipping to a long distance is quite common in the car
                            shipping industry. Therefore, AQ Movers Solution offers
                            the required staff and equipment to ship your car to your
                            location, no matter how far it is. We are industry experts with
                            years of experience in shipping a car to Florida from any
                            far-off location of the country with safety and efficiency.
                        </p>
                        <a class="btn text-nowrap bgCOLOR1" (click)="getQuote()">
                            Get a Quote
                        </a>
                    </div>
                    <div class="col-lg-6 text-center align-self-center">
                        <img src="../../assets/images/states-images/Florida/Long-Distance Vehicle Transport Florida.png" class="w-100" alt="img" />
                    </div>
                </div>
        </section>
    <section class="s8">
        <div class="container mt-3 mb-3">
            <div>
                <h3 class="h1 text-center">
                    Different Ways Of Shipping A Car In Florida
                </h3>
                <p class="h6 text-center">
                    Before hiring the services of a Florida auto transport company for
                    the transport of your car, you must be aware of several ways of
                    shipping available in Florida. These are:
                </p>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="row">
                        <div class="col-lg-10 col-10 col-md-10 col-sm-10">
                            <h3 class="text-left">Door To Door</h3>
                        </div>
                        <div class="col-lg-2 col-2 col-sm-2 col-md-2 d-flex justify-content-left align-items-start">
                            <img src="../../assets/images/states-images/Florida/Door To Door.png" alt="ICON"  />
                        </div>
                    </div>
                    <div>
                        <p>
                            A car transport company picks your vehicle from your doorstep
                            and ships it to your location. This is the most comfortable and
                            easy way of shipping your car through auto transporters in
                            Florida.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12 boxes setPadding2 card1 m-auto">
                    <div class="row">
                        <div class="col-lg-10 col-10 col-sm-10 col-md-10">
                            <h3 class="text-left">Terminal To Terminal</h3>
                        </div>
                        <div class="col-lg-2 col-2 col-md-2 col-sm-2">
                            <img src="../../assets/images/states-images/Florida/Terminal To Terminal.png" alt="ICON"/>
                        </div>
                    </div>
                    <div>
                        <p>
                            Terminal to terminal is not that much popular as the door to
                            door auto transport, but still, it is an active component of the
                            industry. Some companies will not offer terminal to terminal
                            shipping as there are not many transport carriers that support
                            terminal to terminal shipping.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="row">
                        <div class="col-lg-10 col-10 col-md-10 col-sm-10">
                            <h3 class="text-left">Terminal To Door</h3>
                        </div>
                        <div class="col-lg-2 col-2 col-md-2 col-sm-2">
                            <img src="../../assets/images/states-images/Florida/Terminal To Door.png" alt="ICON"/>
                        </div>
                    </div>
                    <div>
                        <p>
                            In this mode of shipping, you drop off your car at the terminal
                            and a transport carrier delivers it to your desired location.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 boxes setPadding2 card1 m-auto">
                    <div class="row">
                        <div class="col-lg-10 col-10 col-sm-10 col-md-10">
                            <h3 class="text-left">Door To Terminal</h3>
                        </div>
                        <div class="col-lg-2 col-2 col-sm-2 col-md-2">
                            <img src="../../assets/images/states-images/Florida/Door to Terminal.png" alt="ICON" />
                        </div>
                    </div>
                    <div>
                        <p>
                            This type of transport deals with a carrier picking your vehicle
                            up from your doorstep and shipping it to a nearby terminal to
                            your location.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid bg-white" >
        <div class="row">
            <div class="col-lg-6 col-xl-6 col-xxl-6 borderRightc">
                <div class="px-2 text-center">
                    <img src="../../assets/images/states-images/Florida/International Car Transport Florida.png" alt="img" class="w-100"/>
                </div>
                <h3 class="mt-2 text-center">International Car Transport Florida</h3>
                <div >
                    <p>
                        Looking for the best Florida vehicle transport to ship your car
                        across the borders? Well, don’t. AQ Movers Solution is
                        here to provide you with affordable, cheap, and quick shipping of
                        your car to another country. When shipping a car across borders,
                        AQ Movers mostly makes use of transport carriers and even
                        ships to transport your car.
                    </p>
                    <p>
                        Though we are capable of shipping your car through planes and
                        trains, these are quite expensive methods of shipping. Mostly, our
                        customers choose to ship through either transport carriers or
                        ships; since it is quite easy and affordable to transport a car
                        through transport carriers.
                    </p>
                    <div class="text-center d-flex d-lg-block justify-content-center mb-4 mb-lg-0 text-lg-right">
                        <button class="btn text-nowrap bgCOLOR1" (click)="getQuote()">Get a Quote</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-xxl-6">
                <div class="ms-1 pl-4 text-center mt-4 mt-lg-0">
                    <img src="../../assets/images/states-images/Florida/Luxury And Classic Car Shipping Florida.png" alt="img" class="w-100" />
                </div>

                <h3 class="mt-2 px-3 text-center">Luxury And Classic Car Shipping Florida:</h3>
                <div class="pl-5">
                    <p>
                        While enclosed auto transport is not the cheapest way of shipping
                        your car to/from Florida, by far it is the most secure and safe
                        mode of shipping a classic or a luxury car. If you are a classic
                        car owner, you must be cautious about the original paint and parts
                        of your vehicle while shipping it to/from Florida.
                    </p>
                    <p>
                        In the case of open vehicle transport, your car will be exposed to
                        external factors which can cause damage to your car. Therefore,
                        while shipping a luxury or a classic car, AQ Movers
                        ensures to use of enclosed vehicle transport to provide safe and
                        sound shipment of your car.
                    </p>
                </div>
                <div class=" text-center d-flex d-lg-block justify-content-center text-lg-left">
                    <button class="btn text-nowrap Call d-flex">
                        <div class="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-telephone d-xs-none" viewbox="0 0 16 16">
                                <path
                                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                        </div>
                        (339) 237-3082
                    </button>
                </div>
            </div>
        </div>
    </section>
    <section  class="container bg-smokey mt-4">
        <div class="best-florida">
                    <h2 class="text-center">Auto Transport Safety Measures</h2>
                    <p>
                        AQ Movers solution takes the responsibility to ship your
                        vehicle to your desired location with utmost safety. To accomplish
                        this, we inspect our transport trucks regularly to ensure there
                        are no malfunctions that can compromise your transport.
                    </p>
                    <p>
                        We double-check each car to check if all the cars are properly
                        strapped with the carrier or not. This is to ensure that cars do
                        not move around on top of the carrier.To make your Florida car shipping experience memorable, we
                        properly plan to take such a route which do not offer bad roads
                        or traffic.
                    </p>
        </div>
    </section>
        <section class="container mt-5">
                <div class="row">
                    <div class="col-lg-6 text-center">
                        <img src="../../assets/images/states-images/Florida/Auto Transport Safety Measures.png" class="w-100" alt="img" />
                    </div>
                    <div class="col-lg-6 align-self-center">
                            <p>
                                Even in our open car carriers, your car is protected from
                                external elements by bars and other cars. So, in case of
                                flying pebbles or bad weather, your car is least exposed to
                                any damage. Moreover, our planning ensures to select such
                                routes where there are least chances of bad weather or bumpy
                                roads, all this to protect your vehicle from any potential
                                damage during your Florida car transport.
                            </p>
                        <a class="btn text-nowrap bgCOLOR1" (click)="getQuote()">
                            Get a Quote
                        </a>
                    </div>
                </div>
        </section>
</div>