<!-- inner banner -->
<div class="inner-banner-7 mt-70">
    <app-booking-request></app-booking-request>
</div>
<!-- //inner banner -->
<app-services-bread-crums></app-services-bread-crums>>

<section>
    <div class="container main">
        <section class="single_grid_w3_main py-4" id="reviews">
            <app-reviews></app-reviews>
        </section>
        <div class="row">
            <div class="col-lg-7">
                <h3 class="heading">Vintage & Classic Vehicle Transport</h3>
                <p>Vintage & Classic Automobiles are historical clocks that record the history of the car. Vintage, antique,
                    and classic automobiles are indicative of automotive technical innovation, yet also provide us with a
                    great deal of joy when we drive them now.
                </p>
                <p>Most people value vintage automobiles, which must be shared and transported to various exhibitions and
                    road shows around the United States. Such artifacts require particular security and transport support
                    systems, which AQ Movers can provide.
                </p>
            </div>
            <div class="col-lg-5">
                <img src="../../assets/images/Vintage & Classic Vehicle Transport/Vintage & Classic Vehicle Transport.png" alt="">
            </div>
        </div>
        <div class="importance">
            <h3 class="heading">Importance of Using a Classic Vehicle Transporter</h3>
            <p>When moving classic and antique automobiles, there is a high level of danger and competent direction
                necessary, and such activities should always be left to a professional Classic Vehicle Transporter.</p>
            <p>One should not choose a transporter at random from the internet. AQ Movers specializes in moving
                this kind of costly antiques and classics.</p>
            <p>Be mindful of what you're putting yourself into and the protection you'll receive:</p>
            <div class="row rows">
                <div class="col-lg-6">
                    <div class="abt-grid">
                        <div class="row">
                            <div class="col-3">
                                <div class="abt-icon">
                                    <span>
                                        <img src="../../assets/images/Vintage & Classic Vehicle Transport/Enclosed ICON.png" alt="img"
                                            width="50px">
                                    </span>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="abt-txt">
                                    <h4><b> Enclosed</b></h4>
                                    <p>With totally enclosed trailers, the outside of your historic vehicle is
                                        protected from debris that may fly off the track or surface road. For
                                        classic and antique collectors, enclosed sealed containers are the preferred
                                        option.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 my-lg-0 my-4">
                    <div class="abt-grid">
                        <div class="row">
                            <div class="col-3">
                                <div class="abt-icon">
                                    <span>
                                        <img src="../../assets/images/Vintage & Classic Vehicle Transport/Insured icon.png" alt="img" width="50px">
                                    </span>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="abt-txt">
                                    <h4><b> Insured</b></h4>
                                    <p>Insurance will cover any damage or loss that occurs during the transport of
                                        your classic or vintage vehicle. Inquire about the specifics of the coverage
                                        amounts and, if applicable, any conditions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row rows">
                <div class="col-lg-6">
                    <div class="abt-grid">
                        <div class="row">
                            <div class="col-3">
                                <div class="abt-icon">
                                    <span>
                                        <img src="../../assets/images/Vintage & Classic Vehicle Transport/Experience icon.png" alt="img"
                                            width="50px">
                                    </span>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="abt-txt">
                                    <h4><b> Experience</b></h4>
                                    <p>When dealing with such a crucial work, and especially with expensive
                                        automobiles, an experienced carrier is a better alternative than an ordinary
                                        one. The safety and security challenges of your valuable goods are too much
                                        for newcomers to handle.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 my-lg-0 my-4">
                    <div class="abt-grid">
                        <div class="row">
                            <div class="col-3">
                                <div class="abt-icon">
                                    <span>
                                        <img src="../../assets/images/services-icons/assurance.png" alt="img"
                                            width="50px">
                                    </span>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="abt-txt">
                                    <h4><b> Assurance</b></h4>
                                    <p>If you are hesitant or unconvinced by the work description provided by the
                                        transporter, it is better to avoid that porter and instead choose for one of
                                        the many other possibilities accessible on the market. Every facet of
                                        shipping is covered, ensuring total peace of mind on our end when
                                        transporting your valuable antiques.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="steps">
            <h3 class="heading">
                Essential Pre-shipping Steps
            </h3>
            <p>Take note of the following crucial pre-shipment activities:</p>
            <div class="row">
                <div class="col-7">
                    <ul>
                        <li>
                            <p>Take a few pictures: Take dated, time-stamped images of every component of your vehicle with a
                                high-quality digital camera. Take a dated, time-stamped video of the automobile on the day of
                                shipping as well if possible.</p>
                        </li>
                        <li>
                            <p>Double-check your insurance coverage: Cover everything from the vehicle's security to the route
                                travelled and the expected arrival time (Estimated Time of Arrival).</p>
                        </li>
                        <li>
                            <p>Confirm shipping specifics, including how the vehicle will be secured, the route travelled, and
                                the estimated arrival time (Estimated Time of Arrival).</p>
                        </li>
                        <li>
                            <p>Stacked Vehicles: Inquire whether the carrier will be stacking automobiles. If the transporter
                                can handle double-deck loads, insist on yours being delivered on the top rack.</p>
                        </li>
                    </ul>
                </div>
                <div class="col-5 align-self-center">
                    <img src="../../assets/images/Vintage & Classic Vehicle Transport/Essential Pre-shipping Steps.png" alt="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 align-self-center">
                <img src="../../assets/images/Vintage & Classic Vehicle Transport/Preparing Your Classic Vehicle for Shipment.png" alt="">
            </div>
            <div class="preparing col-lg-7">
                <h3 class="heading">Preparing Your Classic Vehicle for Shipment</h3>
                <ul>
                    <li>
                        <p><span>Go Over the Entire Vehicle: </span>Inspect your car from top to bottom, making a note of
                            what works and what doesn't.</p>
                    </li>
                    <li>
                        <p><span>Exterior:</span> Examine and mark any external features like as trim, mirrors, hood
                            decorations, and any other connected pieces. Make an inventory of the scrapes and existing paint
                            defects. Examine the chromes and their attachments for any missing or loosened connection pins
                            that keep the chrome in place.</p>
                    </li>
                    <li>
                        <p><span>Interior:</span>Take photos of the whole interior, beginning with the headliners, overhead
                            lighting, and general condition. Seat adjustments, armrests, dash elements, and instruments
                            should all be checked and tested.</p>
                    </li>
                    <li>
                        <p><span>Undercarriage:</span>Take notice of the undercarriage. Examine the condition of the brake
                            lines, cables, transmission case, and oil pan. Make a note of any dents, scratches, or other
                            damage that has already occurred.</p>
                    </li>
                    <li>
                        <p><span>Start the Vehicle: </span>To examine drivability, steering, braking, and handling, start
                            the engine and take a test drive.</p>
                    </li>
                </ul>
            </div>
            <div>
                <p>
                    Complete inspections to determine the vehicle's general condition before shipping is a good idea.
                    Any damage that occurs during transportation may be inspected and treated with this way.
                </p>
                <p>When your car reaches at its location, be present and check it right away while keeping witnesses.
                    Take note of any damages (if any) and notify the hauler as soon as possible, documenting and filing
                    a claim for the damages.</p>
                <p>Follow the steps above to share your classics and vintages and assist us in getting them to the next
                    road shows, exhibits, or your desired destination. Get a quote today to protect and preserve your
                    investment.</p>
            </div>
        </div>
    </div>
</section>