<div class="container">
    <section class="text-center how-does">
        <h1>How does AQ Movers work</h1>
        <h3>This guide and easy guide will tell you in 3 simple steps.</h3>
        <!-- <img src="https://agcarshipping.com/wp-content/uploads/2021/08/imageedit_1_2591252215-1024x386.png"
            alt="How it works." loading="lazy"> -->

        <p>Your vehicle is your asset, we get that safely. Our
            professional staff is always ready to respond to every one
            of the inquiries of the client, as like “How accomplishes
            auto shipping work?”. Whether you are purchasing a car
            online from a car selling website, you need the best auto
            vehicle company at a fair cost, and with a good rating, you
            will find AQ Movers for this.</p>
    </section>
    <section class="quote">

        <div class="display">
            <div class="content">
                <!-- <h2>Quote and book your order</h2> -->
                <h2>Quote and book your order with AQ Movers </h2>
                <h4>Quote Online or Call one of our expert team members </h4>
                <ul>
                    <li>We offer competitive prices and a range of auto transport services for all your vehicle shipping needs! </li>
                    <li>Book your order through the online link in the email you received with your Quote or call one of our professional car shipping agents any time 24/7.</li>
                    <li>Once your order is placed, your personal shipping agent will reach out to you to confirm the details of your shipment. Then we get right to work on assigning an insured and vetted carrier that will safely transport your vehicle based on the schedule that you asked for!</li>
                </ul>
                <p>For an online statement, go to our website, phone, or start the process with one of our experts. Please feel free to have a look at our operations. We guarantee that you will see that our prices are highly competitive, and we take pride in beating any market competitive rates!</p>
                <p>When you're ready to hire us (like so many others before you), simply click the link in your email or on the quotation page, start a conversation with us, or phone to schedule your car pickup.</p>
                <p>If you have any questions regarding financing or want to learn more about our secure payment options, our experienced staff is here to assist. Simply inquire when you come to see us.</p>
            </div>

            <img src="https://agcarshipping.com/wp-content/uploads/2021/04/AG-CAR-SHIPPING-1fstep.png"
                alt="Get free auto transport quote and book your order." loading="lazy">
        </div>

    </section>
    <section class="pick">

        <div class="display">
            <div class="content">
                <h2>AQ Movers Picks Up Your Vehicle
                </h2>
                <p>Your driver will provide you a schedule of when your vehicle will be picked up, what time it will be picked up, and how easy it will be to travel to the pickup place. </p>
               
                <ul>
                    <li>AQ Movers schedules a driver for vehicle pick up at an easily accessible location. You will be directly in contact with the driver as he travels to pick up your vehicle.</li>
                </ul>
                <p>When your driver and you meet; you will both inspect your vehicle and sign a bill of loading together. After that, bid your driver farewell and leave the rest to us.</p>
                <p>We'll look after your vehicle, and you can check in by using our online chat or calling your driver directly to see where your vehicle is on the journey to its destination.</p>
               <ul>
                   <li>At pick up, your driver will conduct a 30-point inspection on your vehicle and record those details on the Bill of Lading (inspection sheet).</li>
               </ul>
            </div>
            <img src="https://agcarshipping.com/wp-content/uploads/2021/04/AG-CAR-SHIPPING-2-step-1024x576.png"
                alt="Transporter gets your car from the pickup location." loading="lazy">
        </div>

    </section>
    <section class="recive">

        <div class="display">
            <div class="content">
                <h2>
                    Receive your vehicle
                </h2>
                <h4>Safe and Timely
                    Delivery of your Vehicle!</h4>
                    <p>Reuniting you with your vehicle is a thrilling moment, and one that AQ Movers aims to accomplish.</p>
                    <p>When your vehicle gets close to your destination, your driver will call you so you may meet at the drop-off point.</p>
                    <p>Following a thorough examination to verify that everything is in working order (as we assure you it will). In most cases, you'll sign the bill of loading to acknowledge transportation. Few points to note:</p>
               <ul>
                   <li>We are in touch with our customers while their vehicle is in transit to provide the best experience possible.</li>
                   <li>Your driver will reach out to you prior to delivery of your vehicle!</li>
                   <li>Upon delivery of your vehicle, a 30-point delivery inspection happens to make sure everything is good to go! At that point you sign off on the Bill of Lading (inspection sheet) to release the vehicle and settle the remaining payment balance.</li>
               </ul>
            </div>
            <img src="https://agcarshipping.com/wp-content/uploads/2021/04/AG-CAR-SHIPPING-3-step-1024x576.png"
                alt="Transporter delivers your vehicle safely at the drop-off location." loading="lazy">
        </div>

    </section>
</div>