<div class="container">
  <div class="abcdd">
    <ngx-slick-carousel class="carousel CategorySlider" [config]="slideConfig">
      	<div ngxSlickItem *ngFor="let item of image" class="slide">
			<div class="card">
				<div class="card-body">
					<div class="d-flex justify-content-between">
						<h6 class="card-title">{{item?.name}}</h6>
						<div class="d-flex Ratingg">
							<i class="fa fa-star"></i>
							<i class="fa fa-star"></i>
							<i class="fa fa-star"></i>
							<i class="fa fa-star"></i>
							<i class="fa fa-star"></i>
						</div>
					</div>
					<p class="card-text">{{item?.review}}</p>
				</div>
			</div>
      	</div>
    </ngx-slick-carousel>
  </div>
</div>