<section class="section section-faq section-faq-616938942fa8f align-w3 pt-xl-8 pt-lg-7 pt-6  " id="faq">
  <div class="container ">
    <header class="section-heading text-center">
      <h2 class="section-heading-title h2 ">Frequently Asked Questions</h2>
      <div class="section-heading-subtitle">
        <h6>Get answers to some of the most commonly asked questions about the vehicle shipping process.</h6>
      </div>
    </header>
    <div class="accordion faq" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button acrd-btn" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Why should I choose AQ Movers?
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We have expert team members on staff that will ensure that your shipment arrives on schedule.
                Furthermore, you may rely on our experienced staff to assist you at any time during the procedure. In
                the event that an issue arises, we are ready to assist. AQ Movers offers a team of
                seasoned and professional drivers that have shown (throughout the years) to be very dependable and
                experienced in completing even the most difficult jobs. We make every effort to give all pertinent
                information, such as price rates and dates, as well as to keep you informed by email, phone, and text
                messaging. We are completely dedicated to our clients, valued customers, and business partners. AQ Movers
                Transport Solutions is ready to assist you whenever you want expert shipping services, and we take pride
                in our customer service. We provide a dedicated service and offer dedicated 24/7 phone, e-mail, live
                chat, and message assistance.</p>
            </div>
          </div>

        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            What is the cost of shipping a vehicle?
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>The pricing of vehicles is based on current market patterns for automobile transportation across the
                United States. Your total cost will be determined by the type of vehicle you want to transport, where it
                needs to go, its size, vehicle condition (running, non-running, inoperable), total transport distance
                from origin to destination, transport type (open or enclosed trailer shipment), and, finally, the time
                of year you book.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            How far in advance should I schedule the transport of my vehicle?
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We strongly advise that you arrange your moving as soon as possible to guarantee that your vehicle is
                transported in the time that best suits your needs. Typically, we can have a truck assigned to your
                vehicle and on its way to pick it up within 1 to 3 days of the vehicle becoming available for pick-up.
              </p>
              <p>If your vehicle is available for immediate pick-up, we will work with you to arrange it as close to
                your specified pick-up date as feasible. It is advisable to submit transportation requests three to four
                days ahead of the available date. It is beneficial to plan your vehicle transportation ahead of time,
                although it is not always essential.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            What do I need for reservation? Do I have to pay everything when I make a reservation?
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>For reservation you would need to share these details:</p>
              <ul>
                <li>exact pick-up</li>
                <li>delivery address</li>
                <li>names and phone numbers of people who will be picking-up and delivery and </li>
                <li>first available pick-up date. </li>
              </ul>
              <p>We don't charge anything up ahead for making a reservation. You will be required to pay a deposit after
                we have secured your position on the trailer. The balance is payable at the time of pick-up or delivery,
                and you may pay the driver directly with cash, cashier's check, or money order.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            Are there any additional costs?
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Certainly not. Our quoted price includes everything. It includes door-to-door service, full
                bumper-to-bumper insurance, all taxes and tolls, and free storage of personal things up to 100 lbs.
                within the vehicle.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            How does AQ Movers calculate my shipping cost?
          </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>There are a few factors that affect the auto transport cost: </p>
              <ul>
                <li>route distance, </li>
                <li>vehicle size and </li>
                <li>condition (running or non-operable), </li>
                <li>the type of trailer that would be used (open or enclosed) and </li>
                <li>the season. </li>
              </ul>
              <p>If you want to move your vehicle down to Florida in the fall, for example, the cost will be greater
                owing to the massive volume of traffic due to the snowbirds heading south to Florida.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            What different payment options does AQ Movers offer?
          </button>
        </h2>
        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We accept a variety of payment options. Credit or debit vehicles are the most frequent methods of
                payment. Clients pay a portion charge that ranges from 10% to 20% of the total amount once we have
                locked down your vehicle. The remaining payment is paid in full at the time of delivery. Once your
                vehicle has been picked up, you can pay the outstanding balance. You won't have to worry about paying on
                delivery this way. Mastercard Visa, American Express, and Discover are all accepted. You may also use
                PayPal to make a payment.</p>
            </div>
          </div>
        </div>
      </div>


      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEight">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
            What payment methods are available on delivery?
          </button>
        </h2>
        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>On delivery, most drivers do not take credit cards or personal cheques. If you have an outstanding
                amount due on delivery, make sure you have cash, a cashier's check, or a money order to pay the drive
                immediately.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingNine">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            How long does it take for my vehicle to be picked up?
          </button>
        </h2>
        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>This is dependent on one factor: the time of pick-up. If your vehicle must be picked up from a large
                metropolitan region, it will almost always be picked up on the precise day you specify. Keep in mind
                that delays might happen due to weather, traffic, or poor road conditions. It may take 1-3 days for your
                vehicle to be picked up if it is located in a more remote region.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
            How long does it take for my vehicle to be delivered?
          </button>
        </h2>
        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>You can feel assured and have our guarantee that your vehicle will arrive on time once we have verified
                your dates with your driver. There may be delays due to inclement weather or unforeseen truck breakdowns
                encountered by the driver end route. The time it takes to ship from coast to coast is generally between
                6 and 9 days. It takes 4 to 6 days to ship a vehicle from north to south and vice versa. Once the
                vehicle has been picked up, auto shipment under 500 miles should take 1 to 3 days.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEleven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
            Are there different types of vehicle transport to choose from?
          </button>
        </h2>
        <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We provide a variety of vehicle transportation choices, with the most popular being open trailer travel
                followed by enclosed transport services.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwelve">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
            What does door to door means?
          </button>
        </h2>
        <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Our trucker will pick up your vehicle at any address you choose and transport it to any place you
                specify if the drive-in is accessible to an 18-wheeler. If that is not possible, we can arrange for the
                driver to meet you around the corner or at a larger parking lot.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirteen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
            Will my vehicle be loaded onto other trucks while in transit?
          </button>
        </h2>
        <div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Certainly not. AQ Movers ensures that your vehicle arrives on the same truck on which
                it was loaded. Only if the truck that picked up your vehicle breaks down on a route will your vehicle be
                transported by another truck, and AQ Movers ensures to find a replacement to finish
                your work on time and on schedule.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingForteen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseForteen" aria-expanded="false" aria-controls="collapseForteen">
            Can I choose a particular spot on the trailer?
          </button>
        </h2>
        <div id="collapseForteen" class="accordion-collapse collapse" aria-labelledby="headingForteen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Most of the time, drivers will load your vehicle wherever they believe is safe. When it comes to
                loading your vehicle, these professionals have a lot of knowledge and have to cope with a lot of
                variables. They must ensure that all the vehicles are correctly fitted so that they do not exceed the
                truck's permissible length, as well that all federal requirements are followed and that none of the
                axles are overweight.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFifteen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
            What is the difference between Open Transport and Enclosed Transport?
          </button>
        </h2>
        <div id="collapseFifteen" class="accordion-collapse collapse" aria-labelledby="headingFifteen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>The most popular technique of transporting a vehicle is open auto transport. Approximately 95% of the
                vehicles are moved via open transit. There is no need to be concerned about your vehicle being damaged
                because the open transit is covered by a $250K insurance policy. Open transit is typically $400 to $600
                less expensive than enclosed transportation. Most clients that use covered transportation have
                high-value vehicles that require extra security. Enclosed transport is preferred for more expensive
                super vehicles since the insurance coverage is higher than open transport.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSixteen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
            Can I ship my personal belongings?
          </button>
        </h2>
        <div id="collapseSixteen" class="accordion-collapse collapse" aria-labelledby="headingSixteen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Personal possessions up to 100 pounds are allowed free of charge, but they must be stored in the trunk
                or rear compartment of your vehicle. It would be extra if you wanted to put more than 100 pounds in the
                vehicle, and it had to be in the front or rear seat, below window level, and it couldn't be anything
                heavy, and the driver seat had to be always unoccupied. Personal things in your vehicle are not covered
                by insurance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSeventeen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
            How much stuff can I put in my vehicle?
          </button>
        </h2>
        <div id="collapseSeventeen" class="accordion-collapse collapse" aria-labelledby="headingSeventeen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Household objects cannot be transported in a vehicle while it is in transit, according to the
                Department of Transportation. Personal items left in the vehicle are sent solely at the responsibility
                of the owner. If the driver reaches at the pickup site and notices that your vehicle is banged to the
                ground due to the added weight, he may ask you to remove your goods or charge you more.</p>
              <p>Truck drivers shall not be held liable for any damages resulting from the storage of home items within
                your vehicle.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingEighteen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
            Is my vehicle going to be insured?
          </button>
        </h2>
        <div id="collapseEighteen" class="accordion-collapse collapse" aria-labelledby="headingEighteen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Yes, we offer full bumper to bumper insurance, which covers your vehicle for at least $100.000 in
                damage of any type for open trailer travel and $250.000 for enclosed trailer freight. This includes
                minor scratches, complete damage, theft, and fire. Your vehicle will be fully insured while it is on our
                trailer, but your personal things will not be covered.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingNineteen">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
            AQ Movers promises that my vehicle will be fully insured while in transit. What do I need
            to know?
          </button>
        </h2>
        <div id="collapseNineteen" class="accordion-collapse collapse" aria-labelledby="headingNineteen"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>During transportation, your vehicle is fully covered by the trucker’s insurance ranging from $250K to
                $1 million. Please bear in mind that damages caused by hail, floods, storms, avalanches, and so on as
                well as damages caused by road debris, are not covered. If you are concerned about this, we may arrange
                for your vehicle to be transported in an enclosed vehicle carrier for an extra cost.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwenty">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
            What if my vehicle is damaged while in transit?
          </button>
        </h2>
        <div id="collapseTwenty" class="accordion-collapse collapse" aria-labelledby="headingTwenty"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>If you get your vehicle and detect damage, you must record it on the bill of lading right away. Then,
                to report the damage, call AQ Movers. It will be extremely beneficial for us to be
                aware of this additional harm as soon as possible so that we can address the concerns as quickly as
                possible. Ensure that the driver does not leave without you reporting the damage and the driver signing
                the bill of lading</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
            After a damage claim has been submitted, what do I do?
          </button>
        </h2>
        <div id="collapseTwentyOne" class="accordion-collapse collapse" aria-labelledby="headingTwentyOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We will require you to present both bills of lading as well as three quotations from a licensed repair
                shop. Our experienced staff will help you with the arbitration to ensure you receive your fair share of
                the damage.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
            Do I need to be present for pickup or delivery?
          </button>
        </h2>
        <div id="collapseTwentyTwo" class="accordion-collapse collapse" aria-labelledby="headingTwentyTwo"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Someone must be present during the collection and delivery of your vehicle. You or anyone you authorize
                can sign the Bill of Laden and watch our driver's vehicle inspection in person. You may go after the
                inspection is completed and the Bill of Laden is signed.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
            I will not be available for the pickup and/or delivery, what do I do?
          </button>
        </h2>
        <div id="collapseTwentyThree" class="accordion-collapse collapse" aria-labelledby="headingTwentyThree"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>On the day of pick-up or delivery, you might have a family member, a friend, or a coworker present. It
                is critical, however, that they are properly informed on the inspections that take place during loading
                and unloading. The person transporting the vehicle must sign the bill of lading, which includes a
                description of the vehicle's condition.</p>
              <p>If someone else is receiving your vehicle, they must be advised that they must check it thoroughly upon
                delivery and certify that it is in the same condition as when it was loaded. Any damages must be noted
                on the same bill of lading by the person receiving the automobile.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
            What paperwork do I need to sign upon pick-up?
          </button>
        </h2>
        <div id="collapseTwentyFour" class="accordion-collapse collapse" aria-labelledby="headingTwentyFour"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>You merely need to give the driver the keys. You don't need your title or insurance. All you must do
                now is sign off on the bill of lading indicating your vehicle has been picked up, and your vehicle will
                be on its way to its drop-off location.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
            My vehicle should be picked up from the auction, what should I know?
          </button>
        </h2>
        <div id="collapseTwentyFive" class="accordion-collapse collapse" aria-labelledby="headingTwentyFive"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>If you want the driver to pick up your automobile at the auction, we'll need information like the firm
                name and address, VIN, Lot, and Buyer's number before the pickup date.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentySix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
            Once a driver is assigned to my vehicle job, what can I expect next?
          </button>
        </h2>
        <div id="collapseTwentySix" class="accordion-collapse collapse" aria-labelledby="headingTwentySix"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>As soon as a driver has been assigned, AQ Movers will give you with the driver's name
                and phone number. On the day of the relocation, the driver will contact you 24 hours before the pickup
                and again one hour before the pickup. When the driver arrives at your location, he will conduct a
                vehicle check and need you to sign the bill of lading. Finally, the driver loads your vehicle and
                departs from your location.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentySeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
            Can you still ship my vehicle if it is inoperable?
          </button>
        </h2>
        <div id="collapseTwentySeven" class="accordion-collapse collapse" aria-labelledby="headingTwentySeven"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Yes, however you must specify the exact condition while placing your order. An inoperable fee of about
                $200 will be applied to the shipping cost if your vehicle is not operational. Please notify us if your
                vehicle is inoperable ahead of time so that we can assign the appropriate driver to transport your
                vehicle.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyEight">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
            How do I track my vehicle while in transit?
          </button>
        </h2>
        <div id="collapseTwentyEight" class="accordion-collapse collapse" aria-labelledby="headingTwentyEight"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We'll provide you details, including expected pick-up and delivery times. We will also provide you with
                the driver's name and phone number so that you may contact him directly. If he is unavailable to take
                your call, please contact AQ Movers, and we would be happy to help you.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwentyNine">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
            Is AQ Movers a licensed and bonded company?
          </button>
        </h2>
        <div id="collapseTwentyNine" class="accordion-collapse collapse" aria-labelledby="headingTwentyNine"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>AQ Movers is fully licensed by the U.S. Department of Transportation and the Federal
                Motor Carrier & Safety Administration. Our </p>
              <ul>
                <li>MC# is 01323510 and </li>
                <li>our DOT# is 3739931.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirty">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
            AQ Movers is a broker, how does that affect me?
          </button>
        </h2>
        <div id="collapseThirty" class="accordion-collapse collapse" aria-labelledby="headingThirty"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We can give the best vehicle shipping service since AQ Movers is a certified broker.
                Thousands of professional automobile transporters are part of our network. Our network has grown and
                evolved over time so that we can always guarantee a safe and timely pick-up, as well as rapid and open
                communication. AQ Movers is responsible for ensuring that the driver's insurance pays
                the damage in a timely way in the unlikely case of a damage.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyOne" aria-expanded="false" aria-controls="collapseThirtyOne">
            Can I choose specific times for my vehicle's pickup and delivery?
          </button>
        </h2>
        <div id="collapseThirtyOne" class="accordion-collapse collapse" aria-labelledby="headingThirtyOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Our carrier will pick up and deliver your vehicle within a certain time frame. This might take anywhere
                from 2 to 4 hours. They will contact you about a week before the pick-up or delivery to confirm the time
                and location.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyTwo" aria-expanded="false" aria-controls="collapseThirtyTwo">
            What should I do before pickup day?
          </button>
        </h2>
        <div id="collapseThirtyTwo" class="accordion-collapse collapse" aria-labelledby="headingThirtyTwo"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Before the day of pickup, you should:</p>
              <ol>
                <li>Perform a brief inspection of your vehicle.
                  <p>Before our experienced carrier take ownership of your vehicle, there are a few items they require
                    from you:</p>
                  <ul>
                    <li>Gather your vehicle's keys, including those for the gas cap and trunk if necessary.</li>
                    <li>Please let us know if you've made any aftermarket changes.</li>
                    <li>1/4 gallons of gas in the tank.</li>
                    <li>Tire pressure that is appropriate.</li>
                    <li>The driver's door and window are both functional.</li>
                    <li>Brakes that work.</li>
                    <li>Battery is fully charged.</li>
                    <li>There are no leaks.</li>
                  </ul>
                </li>
                <li>Remove your personal belongings.
                  <p>We cannot transport personal effects inside the vehicle. Please remove personal items from the
                    interior of your vehicle. </p>
                </li>
                <li>Remove accessories and aftermarket parts.
                  <p>t's recommended taking the time to remove or detach any external or interior appendages that might
                    be harmed during loading or transit:</p>
                  <ul>
                    <li>All alarm systems should be turned off.</li>
                    <li>Remove any antennas that aren't retractable.</li>
                    <li>Remove any aftermarket interior and dash accessories.</li>
                    <li>Luggage, ski, and bike racks should all be taken down.</li>
                    <li>Remember to bring your garage door opener, music collection, and other personal belongings. Even
                      your toll transponder qualifies (e.g., EZ Pass).</li>
                  </ul>
                </li>
                <p>On pickup day</p>
                <li>
                  Confirm delivery-day contact information.
                  <p>When the car carrier picks up your automobile, you'll examine documentation, confirm the delivery
                    address, and provide the name and contact information for the person who will receive the vehicle
                    when it arrives at its destination. It's a good idea to provide multiple phone numbers.</p>
                </li>
                <li>
                  Take note of your vehicle’s condition.
                  <p>The experienced trucker will accompany you around your vehicle, noting any existing external
                    damage. Taking photographs or video of the vehicle on pickup day is always a good idea to have an
                    extra record of its condition at hand-off. Make sure you have a copy of the Bill of Lading from the
                    driver.</p>
                </li>
                <li>
                  Sit back and relax!
                  <p>Your vehicle is in the hands of skilled specialists who will move it with great vehicle, safely,
                    and on schedule.</p>
                </li>
                <p>On delivery day</p>
                <li>
                  Perform a detailed vehicle inspection.
                  <p>We are certain that we provide the best service in the auto shipping industry, but you should
                    examine your vehicle fully when it arrives. Examine the Bill of Lading and compare your notes to the
                    photographs you took on pickup day. We are certain that you will discover that your vehicle was
                    delivered with meticulous care and attention to detail. Take some time to double-check that your
                    vehicle has arrived in good condition. If your vehicle is damaged in the odd event that it is, make
                    sure it is documented on the Bill of Lading.</p>
                </li>
                <li>
                  Sign off and enjoy your ride!
                  <p>Your expert vehicle transporter will ask you to sign the Bill of Lading and pay the remaining
                    payment to certify successful delivery after you are completely happy with the condition of your
                    vehicle upon arrival.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyThree" aria-expanded="false" aria-controls="collapseThirtyThree">
            How does the AQ Movers Wash Program work?
          </button>
        </h2>
        <div id="collapseThirtyThree" class="accordion-collapse collapse" aria-labelledby="headingThirtyThree"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <ol>
                <li>Book your auto transport with us.</li>
                <li>Collect your bill of lading.</li>
                <li>Get your vehicle washed.</li>
              </ol>
              <p>Choose your favorite vehicle wash and get your vehicle cleaned within seven days of your vehicle being
                delivered. We will reimburse you up to a $20 maximum for the vehicle wash. Be sure to keep your vehicle
                wash receipt as we’ll need a copy to secure your refund.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyFour" aria-expanded="false" aria-controls="collapseThirtyFour">
            What should I do with my E-Z Pass or automatic collection pass?
          </button>
        </h2>
        <div id="collapseThirtyFour" class="accordion-collapse collapse" aria-labelledby="headingThirtyFour"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Please take your E-ZPass or any other electronic toll collecting device out of your vehicle and mail it
                or bring it with you to your destination. Because of the nature of automated toll road systems, if your
                gadget is left in your vehicle while it is being transported, you will be charged tolls as if you were
                driving it. AQ Movers shall not be held liable for this unnecessary cost.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyFive" aria-expanded="false" aria-controls="collapseThirtyFive">
            Are you a broker, a car carrier, or an auto transport management company?
          </button>
        </h2>
        <div id="collapseThirtyFive" class="accordion-collapse collapse" aria-labelledby="headingThirtyFive"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Here's a rundown of what those phrases signify and why they're important. Customers are prioritized by
                brokers when they locate vehicles and arrange shipments. Car Carrier own the trucks and transport and
                deliver the vehicles. AQ Movers, for example, provides consumers with end-to-end
                service, acting as a single point of contact from estimate to delivery. When problems develop, an auto
                transport management company such as us, will be able to handle any/all issues quickly.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtySix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtySix" aria-expanded="false" aria-controls="collapseThirtySix">
            What services do you offer?
          </button>
        </h2>
        <div id="collapseThirtySix" class="accordion-collapse collapse" aria-labelledby="headingThirtySix"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>We are all the following: </p>
              <ul>
                <li>An auto transport company will transport vehicles and trucks. </li>
                <li>We offer shipping via open-air trailer along with enclosed trailer shipping as well. There are
                  multi-vehicle and single-vehicle shipping options for our customers as well. </li>
                <li>We also transport a vehicle that has been in an accident. We are also equipped to ship non-working
                  vehicles although majority of our transports are operational vehicles. </li>
                <li>We also coordinate long-distance shipments by boat, train, or even plane.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtySeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtySeven" aria-expanded="false" aria-controls="collapseThirtySeven">
            Are there any restrictions on what types of vehicles you can ship?
          </button>
        </h2>
        <div id="collapseThirtySeven" class="accordion-collapse collapse" aria-labelledby="headingThirtySeven"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Most consumers will have little trouble selecting an auto transport company to send their vehicle, van,
                SUV, or light truck. Special equipment may be required to move a vehicle that is extremely valuable
                (such as a race vehicle or an antique automobile), enormous or substantially customized, or damaged
                after an accident. Because not all auto shipping firms have specialist towing and loading equipment,
                inquire ahead of time if the company can safely handle the vehicle you want to export if it has special
                requests that need to be accommodated.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyEight">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyEight" aria-expanded="false" aria-controls="collapseThirtyEight">
            Can you give me a free quote?
          </button>
        </h2>
        <div id="collapseThirtyEight" class="accordion-collapse collapse" aria-labelledby="headingThirtyEight"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>You should never feel obligated to choose a vehicle shipping company just because you received a price
                from them. Most shippers are aware that you will receive bids from other providers and are unconcerned
                about it. A deposit should not be required to obtain an estimate, so if a vehicle shipping firm will
                only provide you with a price if you commit, look elsewhere. Many organizations now offer online
                calculators that can provide you with a free estimate but speaking with a professional directly will
                often provide you with a more accurate quotation.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThirtyNine">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseThirtyNine" aria-expanded="false" aria-controls="collapseThirtyNine">
            How much will my shipment cost?
          </button>
        </h2>
        <div id="collapseThirtyNine" class="accordion-collapse collapse" aria-labelledby="headingThirtyNine"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Your quotation will be more accurate if you chat with a representative from a vehicle transport
                business directly since you'll be able to discuss any demands or preferences you have. When you contact,
                make sure you have as much information as possible about your vehicle and shipment so the representative
                can offer you an exact quotation.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingForty">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseForty" aria-expanded="false" aria-controls="collapseForty">
            Does this price include everything, or will there be additional costs?
          </button>
        </h2>
        <div id="collapseForty" class="accordion-collapse collapse" aria-labelledby="headingForty"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Before you book, double-check that the figure you receive is the price you'll pay. You can request an
                itemized description of what the stated price includes and specify that you'd like to know about any
                charges, fees, or other costs that aren't included in the quotation right away. That way, if you're
                charged more than you expected, you may compare your invoice to the itemized service agreement you got
                before booking.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyOne" aria-expanded="false" aria-controls="collapseFortyOne">
            How do you calculate your rates?
          </button>
        </h2>
        <div id="collapseFortyOne" class="accordion-collapse collapse" aria-labelledby="headingFortyOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>The cost of shipping a vehicle is determined by several factors, including the vehicle's make and
                model, the distance it will be transported, your drop-off and pickup locations, the shipping method you
                choose, any special handling requests, whether the vehicle runs, demand, seasonal price fluctuations,
                and the price of gasoline. On most cases, transporting an automobile in an enclosed trailer is more
                expensive than shipping a vehicle on an open carrier. If you're shipping a vehicle over state lines, you
                may spend more than if you're shipping it inside the same state. Due to customs and fees, international
                automobile shipment will always be more expensive. Typically, it costs more to ship larger vehicles like
                vans or trucks.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyTwo" aria-expanded="false" aria-controls="collapseFortyTwo">
            What payment options are available?
          </button>
        </h2>
        <div id="collapseFortyTwo" class="accordion-collapse collapse" aria-labelledby="headingFortyTwo"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Inquire about the payment options offered to you by the prospective transport companies. When a
                shipment is booked or before a customer drops off their vehicle, many auto transport firms want a
                deposit or partial payment. A trustworthy auto shipping firm is less likely to want full payment before
                sending a vehicle. Make sure you understand how the firm you're speaking with handles payments, so
                you're not caught off guard by a bill that arrives before your vehicle has arrived.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyThree" aria-expanded="false" aria-controls="collapseFortyThree">
            Is the auto transport company registered and licensed?
          </button>
        </h2>
        <div id="collapseFortyThree" class="accordion-collapse collapse" aria-labelledby="headingFortyThree"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>It's not difficult to determine whether vehicle transport firms are registered and regulated but
                hearing it personally from a representative is preferable. The Federal Motor Carrier Safety
                Administration requires auto transport firms who transfer autos and trucks over state boundaries to get
                a USDOT Number. International vehicle shippers additionally must have a license issued by the Federal
                Maritime Commission. You may search up a company's operating status, safety record, and other
                registration information here if you know its USDOT number.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyFour" aria-expanded="false" aria-controls="collapseFortyFour">
            Is your company fully insured? What does your policy cover? Can I see proof of insurance?
          </button>
        </h2>
        <div id="collapseFortyFour" class="accordion-collapse collapse" aria-labelledby="headingFortyFour"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>It is the law that all legitimate automobile transportation firms be properly insured. The finest
                businesses have sufficient coverage to deal with any problems or crises that may arise. Specific
                questions can and should be asked: What are the basic shipping insurance options offered by the company?
                Is it possible for me to purchase additional insurance? Will your complete automobile be covered if the
                worst happens, or just selected parts? Will you be required to pay a deductible?</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyFive" aria-expanded="false" aria-controls="collapseFortyFive">
            Why are you the best company to book with?
          </button>
        </h2>
        <div id="collapseFortyFive" class="accordion-collapse collapse" aria-labelledby="headingFortyFive"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Our representative's responses (and how quickly they respond) will give you an excellent impression of
                the corporate culture. You want an auto transport service that appreciates its clients and has a proven
                track record of transporting vehicles and trucks to their destinations safely and on schedule. Do you
                have the impression that you're asking too many questions, or that your representative is annoyed by
                them? This is frequently a major red flag.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortySix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortySix" aria-expanded="false" aria-controls="collapseFortySix">
            What are my options when it comes to the timing of my shipment?
          </button>
        </h2>
        <div id="collapseFortySix" class="accordion-collapse collapse" aria-labelledby="headingFortySix"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>First, if you need your vehicle to ship on or around a certain date and/or to arrive on or around a
                certain date, make sure the person you are talking to knows that. Expedited vehicle shipping, or
                guaranteed pickup, is possible, though it’s more expensive. An auto transport company may be able to
                offer you several time frame options at different costs.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortySeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortySeven" aria-expanded="false" aria-controls="collapseFortySeven">
            Do you have customer support?
          </button>
        </h2>
        <div id="collapseFortSeven" class="accordion-collapse collapse" aria-labelledby="headingFortySeven"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Chances are that once your vehicle is on the road, you won’t need frequent updates. Unless, that is,
                you’re shipping an especially valuable vehicle or you’re shipping your vehicle or truck on a tight
                schedule. Small brokers and Carrier are usually unavailable after hours, which can be inconvenient or
                even stress-inducing. One of the reasons many people choose larger, nationwide auto transport management
                companies is that they enjoy the peace of mind of knowing they can get updates while vehicles are en
                route.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyEight">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyEight" aria-expanded="false" aria-controls="collapseFortyEight">
            What are my choices for drop-off and pickup locations?
          </button>
        </h2>
        <div id="collapseFortyEight" class="accordion-collapse collapse" aria-labelledby="headingFortyEight"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>You don't have to travel long distances just to pick up your vehicle. The most drop-off and pickup
                sites are typically accessible with larger vehicle transport firms, but you should always double-check
                that these hubs are handy for you. Door-to-door service is frequently provided, although it is usually
                more expensive. When you choose door-to-door service, your vehicle will be delivered to your house (or
                workplace) rather than being dropped off at a terminal.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFortyNine">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFortyNine" aria-expanded="false" aria-controls="collapseFortyNine">
            What documents will I need to ship my vehicle? </button>
        </h2>
        <div id="collapseFortyNine" class="accordion-collapse collapse" aria-labelledby="headingFortyNine"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Most of the time, all you'll need is your registration and insurance paperwork, as well as a valid ID.
                Some vehicle shipping firms will also ask for a copy of your driver's license. This is just for delivery
                within the United States. If you're exporting a vehicle overseas, the country that will receive your
                vehicle will have additional restrictions. You run the danger of your automobile not being released from
                the port at its destination if you don't follow the rules.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFifty">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseFifty" aria-expanded="false" aria-controls="collapseFifty">
            Do I need to be present for pickup and delivery?
          </button>
        </h2>
        <div id="collapseFifty" class="accordion-collapse collapse" aria-labelledby="headingFifty"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div itemprop="text">
              <p>Because someone will need to hand over and later accept the keys and sign off on the paperwork, most
                vehicle transport firms will demand an adult to be present at both pickup and delivery (most often the
                inspection report and the bill of lading). That someone, though, does not have to be you. If you can't
                be there personally, you can nominate a friend, coworker, neighbor, or family to be your designated
                agent for pickup and delivery. Check with the firm you're speaking with to see if this is permitted.</p>
            </div>
          </div>
        </div>
      </div>



    </div>

  </div>
</section>